import SharedDropdown from '@app/web/src/components/SharedDropdown';
import { IaPlaylistQuestionData } from '@app/web/src/ia/playlistQuestion';
import { IaPlaylistQuestionDataItem } from '@app/web/src/ia/playlistQuestion/types';
import { ExamMode, useAuth } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

type ShareQuestionDropdownProps = {
  anchorEl?: HTMLElement;
  playlistQuestionData?: IaPlaylistQuestionData;
  question?: IaPlaylistQuestionDataItem;
  onClose: () => void;
};

export default function ShareQuestionDropdown({
  anchorEl,
  playlistQuestionData,
  question,
  onClose,
}: ShareQuestionDropdownProps) {
  const { member } = useAuth();
  const clubSlug = useClubSlug();

  if (
    !anchorEl ||
    !playlistQuestionData ||
    !playlistQuestionData.data ||
    !question
  ) {
    return null;
  }

  const { playlistPath } = playlistQuestionData;
  const { latestRoundNo, mode, id, shortId } = playlistQuestionData.data.quiz;
  const modePath = mode === ExamMode.MockExam ? 'exam' : 'practice';
  const quizId = shortId || id;
  const { questionNumber } = question;
  const refer = member ? `?refer=${member.distinctName}` : '';

  return (
    <SharedDropdown
      open
      menuAnchorEl={anchorEl}
      onClose={onClose}
      customTitle="Question"
      virtualRouter={{
        pathname: `/club/[slug]/${modePath}/[quizId]/[roundNo]`,
        query: {
          slug: clubSlug,
          quizId: quizId,
          roundNo: `${latestRoundNo}`,
        },
        asPath: `${playlistPath}${refer}#${questionNumber}`,
      }}
    />
  );
}
