import { useMemo } from 'react';
import { SearchState } from '@app/web/src/utils/search';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers';
import {
  IconListLayoutConfig,
  IconListLayoutItem,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { groupBy } from 'lodash';

export const useThreadSearchUserLayoutConfig = ({
  listState,
  selectedDataset,
  displayDataset,
}: {
  listState: SearchState | null;
  selectedDataset: ThreadSearchUserIconListLayoutItemObj[];
  displayDataset: ThreadSearchUserIconListLayoutItemObj[];
}) => {
  return useMemo(() => {
    let items: IconListLayoutItem[] = [];
    if (selectedDataset.length) {
      items = [`${selectedDataset.length} selected`, ...selectedDataset];
    }

    const filteredDataset = selectedDataset.length
      ? displayDataset.filter(
          (data) => !selectedDataset.some((s) => s.id === data.id)
        )
      : displayDataset;
    if (listState === SearchState.Result) {
      return [
        {
          layout: 'icon-list-layout',
          items,
        } as IconListLayoutConfig,
      ];
    }
    if (listState === SearchState.Searching) {
      items = [
        ...items,
        `${filteredDataset.length} results`,
        ...filteredDataset,
      ];
      return [
        {
          layout: 'icon-list-layout',
          items,
        } as IconListLayoutConfig,
      ];
    }
    if (listState === SearchState.Initial) {
      if (filteredDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items,
          } as IconListLayoutConfig,
        ];
      }

      const groups = groupBy(filteredDataset, (d) => d.metadata?.category);
      const groupedItems = [];

      for (const [category, categorizedItems] of Object.entries(groups)) {
        const groupName = {
          user: 'Followings',
          agent: 'AI',
          group: 'My Groups',
        }[category];

        groupedItems.push(groupName);
        categorizedItems.forEach((item) => groupedItems.push(item));
      }

      return [
        {
          layout: 'icon-list-layout',
          items: [...items, ...groupedItems],
        } as IconListLayoutConfig,
      ];
    }

    return [];
  }, [displayDataset, listState, selectedDataset]);
};
