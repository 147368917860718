import { useMemo } from 'react';
import { VirtualRouter } from '@app/web/src/types/router';
import useShareToDirect from '@app/web/src/widgets/CommonPanels/SharePanel/hooks/useShareToDirect';

export default function useSuggestionMembersLayoutAction({
  virtualRouter,
  setState = () => {},
}: {
  virtualRouter?: VirtualRouter;
  setState?: (id: string, state?: 'loading' | 'sent') => void;
} = {}) {
  const { shareToDirect, pageSettings } = useShareToDirect(virtualRouter);

  return useMemo(
    () => ({
      selectChanged: {
        action: async ({ id }: { id: string }) => {
          if (!pageSettings) return;
          setState(id, 'loading');
          const userIds = [id];
          await shareToDirect({ userIds });
          setState(id, 'sent');
          setTimeout(() => {
            setState?.(id, undefined);
          }, 3000);
        },
      },
    }),
    [pageSettings, shareToDirect, setState]
  );
}
