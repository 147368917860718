import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box } from '@mui/material';
import {
  MainChallenge as MainChallengeIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import { Button } from '@front/ui';
import { ChallengeStatus, ExamMode, useQuizResultSummary } from '@lib/web/apis';

import RedoButton from '../../PlaylistDetailPage/RedoButton';

const styles = {
  alignRight: {
    ml: 'auto',
  },
};

type NewChallengeButtonProps = {
  clubSlug: string;
};
function NewChallengeButton({ clubSlug }: NewChallengeButtonProps) {
  const { t } = useTranslation('notification');

  const handleClick = () => {
    Router.push(`/club/${clubSlug}/start`);
  };

  return (
    <Button
      prefixIcon={<MainChallengeIcon />}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      sx={styles.alignRight}
    >
      {t('cta.user.challenge.status.button.New Challenge', 'New Challenge')}
    </Button>
  );
}

type StartChallengeButtonProps = {
  clubSlug: string;
  quizId: string;
  quizShortId: string;
  data: GetQuizResultSummaryRes;
};
function StartChallengeButton({
  clubSlug,
  quizId,
  quizShortId,
  data,
}: StartChallengeButtonProps) {
  const { t } = useTranslation('notification');

  const startQuiz = () => {
    if (!data) return;
    const { mode } = data.quiz;
    const targetUrl = mode === ExamMode.MockExam ? 'exam' : 'practice';
    const id = quizShortId || quizId;
    const path = `/club/${clubSlug}/${targetUrl}/${id}/1#1`;
    return Router.push(path);
  };

  const { challenge, quiz } = data;
  if (!challenge) return null;

  if (challenge.status === ChallengeStatus.Ongoing && !challenge.isTaken) {
    return (
      <Button
        prefixIcon={<OtherPlayIcon />}
        onClick={startQuiz}
        onMouseDown={(e) => e.stopPropagation()}
        sx={styles.alignRight}
      >
        {t(
          'cta.user.challenge.status.button.Start Challenge',
          'Start Challenge'
        )}
      </Button>
    );
  }

  return (
    <Box sx={styles.alignRight}>
      <RedoButton
        quizId={quizId}
        roundNo={quiz.latestRoundNo}
        clubSlug={clubSlug}
        panelKeyPrefix={quizShortId || quizId}
      />
    </Box>
  );
}

type ChallengeButtonProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.user.challenge.status' }>;
};

export default function ChallengeButton({ cta }: ChallengeButtonProps) {
  const { data } = useQuizResultSummary({ quizId: cta.quizId });

  if (!data) {
    return (
      <Button
        prefixIcon={<OtherPlayIcon />}
        sx={styles.alignRight}
        loading={!data}
      />
    );
  }

  const { challenge } = data.data;
  if (!challenge) return null;

  if (challenge.status === ChallengeStatus.Expired) {
    return <NewChallengeButton clubSlug={cta.clubSlug} />;
  }

  return (
    <StartChallengeButton
      clubSlug={cta.clubSlug}
      quizId={cta.quizId}
      quizShortId={cta.quizShortId}
      data={data.data}
    />
  );
}
