import { useState } from 'react';
import Router from 'next/router';
import { Skeleton } from '@mui/material';
import { ExamMode } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import usePlaylistInfo from '../../../hooks/usePlaylistInfo';
import usePlaylistSummary from '../../../hooks/usePlaylistSummary';
import RedoCreateQuizPanels from '../RedoCreateQuizPanels';

import BaseRedoButton from './BaseRedoButton';
import ChallengeRedoButton from './ChallengeRedoButton';
import PracticeRedoButton from './PracticeRedoButton';
import StartRedoButton from './StartRedoButton';

type DefaultRedoButtonProps = {
  data: GetQuizResultSummaryRes;
  panelKeyPrefix?: string;
  clubSlug?: string;
};

function DefaultRedoButton({
  data,
  panelKeyPrefix,
  clubSlug: clubSlugProp,
}: DefaultRedoButtonProps) {
  const [loading, setLoading] = useState(false);
  const routerClubSlug = useClubSlug();
  const clubSlug = clubSlugProp || routerClubSlug;
  const { isCompleted } = data.quiz;
  const isTaken = Boolean(data.quiz.latestRoundNo && data.quiz.lastTakenAt);

  const startQuiz = (isContinue?: boolean) => {
    const targetUrl =
      data.quiz.mode === ExamMode.MockExam ? 'exam' : 'practice';
    const id = data.quiz.shortId || data.quiz.id;
    const roundNo = Math.max(data.quiz.latestRoundNo, 1);
    const questionNoHash = isContinue
      ? `#${(data.quiz.continueQuestionIdx || 0) + 1}`
      : `#1`;
    const path = `/club/${clubSlug}/${targetUrl}/${id}/${roundNo}${questionNoHash}`;
    return Router.push(path);
  };

  const handleContinuePractice = async () => {
    setLoading(true);
    await startQuiz(true);
    setLoading(false);
  };

  if (isTaken && !isCompleted) {
    return (
      <BaseRedoButton
        label="Continue"
        color="success"
        loading={loading}
        onClick={handleContinuePractice}
      />
    );
  }

  if (isTaken) {
    return <PracticeRedoButton data={data} panelKeyPrefix={panelKeyPrefix} />;
  }

  return <StartRedoButton panelKeyPrefix={panelKeyPrefix} />;
}

function RedoButtonSkeleton() {
  return <Skeleton width={120} height={36} variant="rounded" />;
}

type RedoButtonProps = {
  panelKeyPrefix?: string;
  clubSlug?: string;
};

export default function RedoButton({
  panelKeyPrefix = RedoCreateQuizPanels.DEFAULT_PANEL_KEY,
  clubSlug,
}: RedoButtonProps) {
  const { data } = usePlaylistSummary();
  const { dynamicType } = usePlaylistInfo();

  if (!data) return <RedoButtonSkeleton />;

  const isChallenge = !!data.data.challenge;
  if (isChallenge) {
    return (
      <ChallengeRedoButton
        data={data.data}
        panelKeyPrefix={panelKeyPrefix}
        clubSlug={clubSlug}
      />
    );
  }
  if (dynamicType) return <StartRedoButton panelKeyPrefix={panelKeyPrefix} />;
  return (
    <DefaultRedoButton
      data={data.data}
      panelKeyPrefix={panelKeyPrefix}
      clubSlug={clubSlug}
    />
  );
}
