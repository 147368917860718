import { useMemo } from 'react';
import useNotificationCustomizeCtas from '@app/web/src/widgets/NotificationPage/hooks/useNotificationCustomizeCtas';
import MessageItem, {
  MessageItemProps,
} from '@lib/ia/src/layouts/ChannelLayout/components/ContentItem/MessageItem';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutsProps } from '@lib/ia/src/layouts/IaLayouts/IaLayouts';
import { ComposerBlock } from '@lib/web/composer';
import InlineLineMarkerRenderer from '@lib/web/editor/EditorThreadTextComposer/components/blocks/InlineLineMarker/InlineLineMarkerRenderer';
import { QuizAudioRender, QuizAudioRenderProps } from '@lib/web/practice';
import ThreadMessage from '@lib/web/thread/ThreadChat/components/ThreadMessage/ThreadMessage';
import ThreadMessageComposer from '@lib/web/thread/ThreadChat/components/ThreadMessageComposer';
import FileBlockRender, {
  FileBlockRendererProps,
} from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock/FileBlockRender';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import ThreadAgentMessage, {
  ThreadAgentMessageProps,
} from '../components/ThreadAgentMessage/ThreadAgentMessage';
import ThreadMentionUser from '../components/ThreadMentionUser';

export default function useThreadRender() {
  const notificationCtas = useNotificationCustomizeCtas();

  return useMemo(
    () => ({
      ...notificationCtas,
      mentionTag: (ev: {
        memberId: string;
        userId?: string;
        agentId?: string;
      }) => <ThreadMentionUser {...ev} />,
      fileBlock: (ev: FileBlockRendererProps) => <FileBlockRender {...ev} />,
      audioBlock: (ev: QuizAudioRenderProps) => <QuizAudioRender {...ev} />,
      agentMessageContent: (ev: ThreadAgentMessageProps) => (
        <ThreadAgentMessage {...ev} />
      ),
      messageItem: (ev: MessageItemProps) => <MessageItem {...ev} />,
      threadMessageItem: (ev: {
        channel: Channel;
        message: StreamMessage<StreamChatGenerics>;
        messages: StreamMessage<StreamChatGenerics>[];
        childChannel?: Channel;
        availableEventNames?: string[];
      }) => <ThreadMessage {...ev} />,
      inlineLineMarkerBlock: (ev: {
        targetId: string;
        displayText: string;
        displayLineText: string;
      }) => <InlineLineMarkerRenderer {...ev} />,
      messageComposer: (ev: {
        defaultBlocks?: ComposerBlock[];
        handleKeyDown?: (
          event: KeyboardEvent,
          text: string,
          changedBlocks: ComposerBlock[]
        ) => void;
        handleBlur?: (event: FocusEvent) => void;
      }) => <ThreadMessageComposer {...ev} />,
      iaLayouts: (ev: IaLayoutsProps) => <IaLayouts {...ev} />,
    }),
    [notificationCtas]
  );
}
