import { useMemo } from 'react';
import { IaRenderContextValue } from '@lib/ia/src/core/IaRender/IaRenderContext';

import AcceptClubInvite from '../CtaActionButtons/AcceptClubInvite';
import ChallengeButton from '../CtaActionButtons/ChallengeButton';
import ChallengeInvitationCard from '../CtaActionButtons/ChallengeInvitationCard';
import ChallengeRecommendList from '../CtaActionButtons/ChallengeRecommendList';
import ClubHome from '../CtaActionButtons/ClubHome';
import FriendFollowButton from '../CtaActionButtons/FriendFollowButton';
import GoalRingCard from '../CtaActionButtons/GoalRingCard';
import LeagueCard from '../CtaActionButtons/LeagueCard';
import PlanCard from '../CtaActionButtons/PlanCard';
import ResendVerifyEmailButton from '../CtaActionButtons/ResendVerifyEmailButton';
import SharedChallengeCard from '../CtaActionButtons/SharedChallengeCard';
import SharedPlaylistCard from '../CtaActionButtons/SharedPlaylistCard';
import SharedSummaryCard from '../CtaActionButtons/SharedSummaryCard';
import StreakCard from '../CtaActionButtons/StreakCard';

export default function useNotificationCustomizeCtas() {
  const renders: IaRenderContextValue = useMemo(() => {
    return {
      'cta.league.current': LeagueCard,
      'cta.goal.ring.*': GoalRingCard,
      'cta.streak.*': StreakCard,
      'cta.shared.summary': SharedSummaryCard,
      'cta.shared.challenge': SharedChallengeCard,
      'cta.email.verification.remind': ResendVerifyEmailButton,
      'cta.shared.*.playlist': SharedPlaylistCard,
      'cta.membership.plan.info.*': PlanCard,
      'cta.challenge.invitation': ChallengeInvitationCard,
      'cta.challenge.recommend.list': ChallengeRecommendList,
      'cta.recommend.challenger.list': ChallengeRecommendList,
      'cta.club.invitation': AcceptClubInvite,
      'cta.follow.followback': FriendFollowButton,
      'cta.club.home': ClubHome,
      'cta.user.challenge.status': ChallengeButton,
    };
  }, []);

  return renders;
}
