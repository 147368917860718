import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ActionSearch as ActionSearchIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  compactNumberFormat,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { AgentViewSlug, useAgents } from '@lib/web/apis';
import {
  useCurrentIaClub,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { getAgentIconPath } from '@lib/web/utils';

export default function ManageAgentsPanelSearch() {
  const { t } = useTranslation('agent');
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const {
    search,
    focused,
    isSearching,
    setSearch,
    setFocused,
    onChange,
    onBlur,
    onFocus,
    debouncedSearch,
  } = useSearchStatus();

  const { club } = useCurrentIaClub();

  const agentsData = useAgents({
    viewSlug: AgentViewSlug.ProfileRhsAgentDefault,
    ...combineSearch(
      !!club && [`agentClub:${club.clubId}`, 'agentClub:eq'],
      !!debouncedSearch && [`agentName:${debouncedSearch}`, 'agentName:contain']
    ),
    sortBy: 'asc',
  });

  const { dataset, isLoading: searchLoading } = agentsData;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: {
      ...agentsData,
      isReachingEnd: true, // FIXME: currently backend ia response has wrong 'totalCount', after backend fix, we can remove limit=0 and remove this line
    },
  });

  const displayDataset: IconListLayoutItemObj[] = useMemo(() => {
    return dataset.map((item) => ({
      id: item.agentId,
      title: item.agentName,
      description: item.longDesc,
      avatarUrl: item.agentPhoto || getAgentIconPath(item.agentName),
      properties: [
        {
          text: t('Users', {
            count: item.userCount,
            formattedCount: compactNumberFormat(item.userCount),
          }),
        },
        {
          text: t('Threads', {
            count: item.threadCount,
            formattedCount: compactNumberFormat(item.threadCount),
          }),
        },
      ],
      action: {
        type: 'event',
        value: 'agentClicked',
      },
      actionMap: {
        hover: {
          value: 'agentHovered',
        },
      },
    }));
  }, [t, dataset]);

  const openAgentProfile = (target: IconListLayoutItemObj) => {
    openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
      agentId: target.id,
    });
  };

  const config: IaLayoutConfig[] = useMemo(() => {
    if (isSearching && !searchLoading && displayDataset.length === 0)
      return [
        {
          layout: 'icon-list-layout',
          items: [t('No result found')],
        },
      ];
    if (isSearching)
      return [
        {
          layout: 'icon-list-layout',
          items: (
            [
              t('Results', {
                count: displayDataset.length,
              }),
            ] as IconListLayoutItem[]
          ).concat(displayDataset),
        },
      ];
    return [
      {
        layout: 'icon-list-layout',
        items: (
          [
            t('Agents', {
              count: displayDataset.length,
            }),
          ] as IconListLayoutItem[]
        ).concat(displayDataset),
      },
    ];
  }, [t, displayDataset, isSearching, searchLoading]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const availableActions = {
    agentClicked: {
      action: (value: IconListLayoutItemObj) => {
        openAgentProfile(value);
      },
    },
    agentHovered: {
      action: updateSuggestItem,
    },
  };

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      focused: suggestItem?.id === id,
    };
  };
  const clearSearchState = () => {
    setSearch('');
    setFocused(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      event.preventDefault();
      openAgentProfile(suggestItem);
      clearSearchState();
    }
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<ActionSearchIcon width="16" height="16" />}
          title={t('Search Agents')}
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                placeholder={t('search.placeholder_bots', 'Search AI...')}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
