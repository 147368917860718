import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import { getSearchState } from '@app/web/src/utils/search';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { useInfiniteScroll, useSearchStatus } from '@lib/web/hooks';

import useOpenGlobalProfilePanel from '../hooks/useOpenGlobalProfilePanel';

import useSearchDisplayDataset from './hooks/useSearchDisplayDataset';
import useSearchLayoutConfig from './hooks/useSearchLayoutConfig';
import useSearchUserDataset from './hooks/useSearchUserDataset';

const styles = {
  action: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '& .MuiButtonBase-root': {
      width: { flex: 1, minWidth: 'unset', px: 0 },
    },
  },
};
type ActionClick = (ev: IconListLayoutItemObj[]) => void;

type SearchUserPanelProps = {
  title: string;
  titleIcon: ReactNode;
  tip?: string;
  buttonTestId?: string;
  excludeMembers?: { userId: string }[];
  autoFocus?: boolean;
  placeholder?: string;
  i18n?: {
    excludeKey: string;
    selectedKey: string;
    resultsKey: string;
    initialKey: string;
  };

  onActionClick: ActionClick;
  renderAction: (ev: {
    selectedCount: number;
    sending: boolean;
    onActionClick: () => void;
  }) => ReactNode;
};

const baseI18Keys = {
  excludeKey: '',
  selectedKey: '## Selected',
  resultsKey: '## Results',
  initialKey: '## Following',
};
export default function SearchUserPanel({
  title,
  titleIcon,
  buttonTestId = 'panel-cta-button',
  placeholder,
  tip,
  excludeMembers,
  autoFocus,
  onActionClick,
  renderAction,
  i18n = baseI18Keys,
}: SearchUserPanelProps) {
  const { t } = useTranslation();
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const [sending, setSending] = useState(false);
  const [selected, setSelected] = useState<IconListLayoutItemObj[]>([]);
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const searchInputRef = useRef<HTMLInputElement>();

  const selectedDataset = useMemo(() => {
    return selected.map((result) => ({
      id: result.id,
      title: result.title,
      description: result.description,
      avatarUrl: result.avatarUrl,
      actionMap: {
        select: {
          value: 'userSelected',
        },
      },
      metadata: result.metadata,
    }));
  }, [selected]);

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const {
    friendSearchData,
    memberSearchData,
    isLoading: searchLoading,
    newUserEmail,
  } = useSearchUserDataset(isSearching ? debouncedSearch : '');

  // initial search results
  const { dataset: friendDataset, totalCount: friendTotalCount } =
    friendSearchData;
  // search results
  const { dataset: searchDataset, totalCount: searchTotalCount } =
    memberSearchData;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: isSearching ? memberSearchData : friendSearchData,
  });

  const handleActionClick = async () => {
    setSending(true);

    await onActionClick(selected);

    setSelected([]);

    setSending(false);
  };

  const searchState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: selected.length > 0,
  });

  const displayDataset = useSearchDisplayDataset({
    state: searchState,
    initialResult: friendDataset,
    searchResult: searchDataset,
    newUser: newUserFormatter(newUserEmail),
    searchLoading,
    excludeMembers,
    i18n,
  });

  const config = useSearchLayoutConfig({
    state: searchState,
    dataset: displayDataset,
    selected: selectedDataset,
    totalCount: isSearching ? searchTotalCount : friendTotalCount,
    i18n,
  });

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      selected: selected.some((item) => item.id === id),
      loading: pendingEmails.includes(id),
      disabled: invitedHistory.some(
        (item) => item.receiver.email === id && !item.resendAble
      ),
      focused: suggestItem?.id === id,
    };
  };

  const availableActions = {
    userSelected: {
      action: (value: IconListLayoutItemObj) => {
        setSelected((st) =>
          st.some((item) => item.id === value.id)
            ? st.filter((item) => item.id !== value.id)
            : [...st, value]
        );
        if (focused) searchInputRef.current?.focus();
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: IconListLayoutItemObj) => {
        inviteToAha([value.id], {
          toastOptions: {
            anchorEl: document.querySelector(
              `[data-testid="${buttonTestId}"]`
            ) as Element,
          },
        });
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
  };

  const selectUser = () => {
    setSearch('');
    searchInputRef.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      suggestItem &&
      !!suggestItem.actionMap?.select
    ) {
      event.preventDefault();

      const status = getItemStatus(suggestItem);
      if (status.disabled || status.selected) return;

      setSelected((st) => [...st, suggestItem]);
      selectUser();
    }
  };

  useEffect(() => {
    if (autoFocus) {
      searchInputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          title={title}
          titleIcon={titleIcon}
          actionComponent={
            <Box sx={styles.action} data-testid={buttonTestId}>
              {renderAction({
                selectedCount: selected.length,
                onActionClick: handleActionClick,
                sending,
              })}
            </Box>
          }
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={placeholder || t('Type to Search')}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
              {!focused && !isSearching && !!tip && !selected.length && (
                <LightbulbCard>{tip}</LightbulbCard>
              )}
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
