import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import ChipsPanelWrap from '@app/web/src/components/ChipsPanelWrap';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import {
  BaseLayoutRightPanel,
  Icon,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { MemberSearchCategory, useMembersByCategory } from '@lib/web/apis';
import {
  useCurrentIaClub,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { emailRegex } from '@lib/web/utils';

import useFloatingProfile from '../../hooks/utils/useFloatingProfile';
import useOpenGlobalProfilePanel from '../../widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';

import { SuggestionPanelProps, SuggestionUser } from './types';
import useAvailableActions from './useAvailableActions';
import useSuggestionLayoutConfig from './useSuggestionLayoutConfig';

const styles = {
  empty: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    px: { xs: 2.5, md: '12px' },
  },
  lightbulb: {
    px: { xs: 2.5, md: '12px' },
  },
};
export default function SuggestionPanel<OtherChipValue = string>({
  otherOptions,
  panelProps,
  selectedItems,
  defaultItems,
  enabledSearchMode,
  disableSelectTooltip,
  searchPlaceholder,
  selectedSearchMode,
  lightbulbText,
  onSearchModeChange,
  renderSelectedTitle,
  renderSelectedItems,
  renderDefaultTitle,
  renderDefaultItems,
  renderTitle,
  renderItems,
  renderNewUser,
  renderModePane,
  renderEmptyText,
  onToggleItem,
  isSelected: isSelectedProps,
  isDisabled: isDisabledProps,
}: SuggestionPanelProps<OtherChipValue>) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [selfSearchMode, setSelfSearchMode] = useState<
    OtherChipValue | MemberSearchCategory
  >(MemberSearchCategory.NoSelect);
  const isSearchModeControlled = selectedSearchMode !== undefined;

  const searchMode = isSearchModeControlled
    ? selectedSearchMode
    : selfSearchMode;
  const setSearchMode = isSearchModeControlled
    ? onSearchModeChange
    : setSelfSearchMode;
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const { showUserIdProfile } = useFloatingProfile();

  const { club } = useCurrentIaClub();
  const clubSlug = club?.clubSlug || '';

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange: onSearchChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const customizePane = renderModePane?.(searchMode);
  const searchEnabled = !customizePane;
  const showResult = isSearching || !!searchMode;

  const suggestionData = useMembersByCategory({
    clubSlug: searchEnabled ? clubSlug : undefined,
    keyword: isSearching ? debouncedSearch : '',
    isExcludeMe: true,
    category: showResult ? searchMode : MemberSearchCategory.TopScore,
  });
  const searchInputRef = useRef<HTMLInputElement>();

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: suggestionData,
    enabled: isSearching,
    dependencies: [searchMode],
  });

  const options: {
    icon: ReactElement;
    display: string;
    tip: string;
    value: OtherChipValue | MemberSearchCategory;
  }[] = useMemo(() => {
    return [
      ...(otherOptions || []),
      ...(enabledSearchMode?.map((mode) => ({
        icon: (
          <Icon
            name={t(`suggestion.chip.icon_${mode}`)}
            width={16}
            height={16}
          />
        ),
        display: t(`suggestion.chip.title_${mode}`),
        tip: t(`suggestion.chip.content_${mode}`),
        value: mode,
      })) || []),
    ];
  }, [enabledSearchMode, otherOptions, t]);

  const isSelected = useCallback(
    (id: string) => {
      return (
        selectedItems?.some((selected) => selected.id === id) ||
        (isSelectedProps?.(id) ?? false)
      );
    },
    [isSelectedProps, selectedItems]
  );

  const isDisabled = useCallback(
    (id: string) => {
      return isDisabledProps?.(id) ?? false;
    },
    [isDisabledProps]
  );
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const [dataset, datasetTotalCount] = useMemo(() => {
    const datasetResult: SuggestionUser[] = [];
    let datasetFilterCount = 0;
    suggestionData.dataset.forEach((item) => {
      if (isSelected(item.userId)) {
        datasetFilterCount += 1;
      } else {
        datasetResult.push({
          id: item.userId,
          avatar: item.nftAvatar || item.avatar || '',
          display: item.displayName || item.distinctName,

          memberInfo: item,
        });
      }
    });

    return [
      datasetResult,
      suggestionData.totalCount === undefined
        ? undefined
        : suggestionData.totalCount - datasetFilterCount,
    ];
  }, [isSelected, suggestionData.dataset, suggestionData.totalCount]);

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !suggestionData.isLoading &&
      !suggestionData.totalCount &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    suggestionData.isLoading,
    suggestionData.totalCount,
    newUserFormatter,
  ]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(focused && dataset);

  const configs = useSuggestionLayoutConfig<OtherChipValue>({
    t,
    newUser,
    showResult,
    searchMode,
    dataset,
    datasetTotalCount,
    selectedItems,
    defaultItems,
    disableSelectTooltip,
    renderSelectedTitle,
    renderSelectedItems,
    renderDefaultTitle,
    renderDefaultItems,
    renderTitle,
    renderItems,
    renderNewUser,
  });

  const availableActions = useAvailableActions({
    onToggleItem,
    updateSuggestItem,
    showUserIdProfile,
    openProfile,
    inviteToAha,
    search,
  });

  const getItemStatus = ({ id }: { id: string }) => {
    return {
      selected: isSelected(id),
      disabled:
        (isDisabled(id) ||
          invitedHistory.some(
            (item) => item.receiver.email === id && !item.resendAble
          )) &&
        id !== newUser?.email,
      loading: pendingEmails.includes(id),
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem && !isDisabled(suggestItem.id)) {
      event.preventDefault();
      searchInputRef.current?.focus();
      onToggleItem?.(suggestItem);
    }
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <ChipsPanelWrap<MemberSearchCategory | OtherChipValue>
          panelProps={panelProps}
          error={
            suggestionData.error ? t('suggestion.search.error') : undefined
          }
          options={options}
          clearTitle={t('suggestion.chip.title_clear')}
          clearTip={t('suggestion.chip.content_clear')}
          value={searchMode}
          unselectedValue={MemberSearchCategory.NoSelect}
          onChange={setSearchMode}
          onRetry={suggestionData.mutate}
          renderInput={
            !customizePane ? (
              <SearchBar
                inputRef={searchInputRef}
                placeholder={
                  searchPlaceholder || t('suggestion.search.placeholder')
                }
                value={search}
                loading={suggestionData.isLoading}
                onChange={onSearchChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={
                  typeof suggestItem !== 'string'
                    ? suggestItem?.display
                    : undefined
                }
                prefixIcon={
                  suggestItem &&
                  typeof suggestItem !== 'string' && (
                    <SquareAvatar src={suggestItem.avatar} size={16}>
                      {suggestItem.display}
                    </SquareAvatar>
                  )
                }
              />
            ) : undefined
          }
        >
          <BaseLayoutRightPanel.ScrollArea
            scrollableNodeProps={{ ref: scrollRef }}
          >
            {customizePane || (
              <>
                {configs.length === 0 &&
                  !focused &&
                  !!lightbulbText &&
                  !suggestionData.isEmpty && (
                    <Box sx={styles.lightbulb}>
                      <LightbulbCard>{lightbulbText}</LightbulbCard>
                    </Box>
                  )}

                {configs && <IaLayouts layouts={configs} />}
                {suggestionData.isEmpty && !newUser && (
                  <Box sx={styles.empty}>
                    <Typography variant="body2">
                      {t('suggestion.search.empty')}
                    </Typography>
                    {renderEmptyText?.(searchMode) || (
                      <Typography variant="body2">
                        {t('suggestion.search.empty', { context: searchMode })}
                      </Typography>
                    )}
                  </Box>
                )}
              </>
            )}
          </BaseLayoutRightPanel.ScrollArea>
        </ChipsPanelWrap>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
