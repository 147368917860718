import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Portal } from '@mui/material';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import {
  ActionClear as ActionClearIcon,
  OtherDelete as OtherDeleteIcon,
  OtherSort as OtherSortIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { getPropertyIcon, TipButton, TitleBar } from '@front/ui';

import SortContext from '../context/SortContext';
import useSortActions from '../hooks/useSortActions';

import SortContent from './SortContent';

const styles = {
  root: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.darker',
    zIndex: 'modal',
  },
  bar: {
    px: 2.5,
  },
  title: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
  },
  titleBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    position: 'relative',
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ml: 'auto',
  },
  toolbarButton: {
    marginLeft: 'auto',
  },
  dot: {
    position: 'absolute',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    bgcolor: 'error.dark',
    top: '-3px',
    right: '-3px',
  },
  filterIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
};

function isSortSummaryPage(page?: string) {
  return !page || page === 'sortSummary';
}

export default function SortPanel({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { back, goPage } = useSortActions();

  const [settings] = useContext(SortContext);
  const { page, selectedCriteria, history, scope } = settings;
  const { removeCriteria, resetSort, hasSort } = useSort(scope);

  const handleDeleteClick = () => {
    if (selectedCriteria && selectedCriteria.id) {
      removeCriteria(selectedCriteria.id);
    }
    goPage({ page: 'sortSummary' });
  };

  const handleResetClick = () => {
    resetSort();
  };

  let title;
  let titleIcon;
  if (isSortSummaryPage(page)) {
    title = t('Sort');
    titleIcon = (
      <Box sx={styles.filterIcon}>
        <OtherSortIcon width={16} height={16} />
        {hasSort && <Box component="span" sx={styles.dot} />}
      </Box>
    );
  } else if (page === 'fieldList') {
    title = t('Add Sort');
    titleIcon = <TestAddIcon width={16} height={16} />;
  } else if (page === 'criteriaDetail') {
    title = selectedCriteria?.field.label;
    titleIcon = getPropertyIcon(selectedCriteria?.field.type, 18);
  }
  let toolComponent;
  if (page === 'criteriaDetail') {
    toolComponent = (
      <TipButton
        title={t('toolbar.Delete sort')}
        sx={styles.toolbarButton}
        onClick={handleDeleteClick}
      >
        <OtherDeleteIcon />
      </TipButton>
    );
  } else if (isSortSummaryPage(page) && hasSort) {
    toolComponent = (
      <TipButton
        title={t('toolbar.Clear all')}
        sx={styles.toolbarButton}
        onClick={handleResetClick}
      >
        <ActionClearIcon />
      </TipButton>
    );
  }

  return (
    <Portal>
      <Box className="right-panel-ignore" sx={styles.root}>
        <TitleBar
          sx={styles.bar}
          title={
            <Box sx={styles.title}>
              {titleIcon}
              <Box sx={styles.titleBox}>{title}</Box>
            </Box>
          }
          backIcon={history.currentIndex > 0 ? 'back' : 'collapse'}
          onBackClick={history.currentIndex > 0 ? back : onClose}
          toolComponent={<Box sx={styles.toolbar}>{toolComponent}</Box>}
        />
        <Box sx={styles.content}>
          <SortContent />
        </Box>
      </Box>
    </Portal>
  );
}
