import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import AuthGuard from '@app/web/src/components/AuthGuard';
import SharedDropdown from '@app/web/src/components/SharedDropdown';
import {
  useIaAgentAccessControl,
  useIaAgentActions,
  useIaAgentUserAction,
} from '@app/web/src/ia/agent';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { SortableOnDragOverOtherItem } from '@front/helper';
import { ProfileManageAIBot as ProfileManageAIBotIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  EmphasizeButton,
  useBaseRightPanel,
} from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  ListLayoutConfig,
  ListLayoutItem,
} from '@lib/ia/src/layouts/ListLayout/types';
import {
  AgentUserViewSlug,
  AgentVisibility,
  buildInfiniteHookMutate,
  useAgentUsers,
  useAuth,
} from '@lib/web/apis';
import { useCurrentIaClub, useInfiniteScroll } from '@lib/web/hooks';
import { getAgentIconPath } from '@lib/web/utils';
import { keyBy } from 'lodash';

import ManageAgentsToolbar from './ManageAgentsToolbar';

const normalizeOrder = <T extends Array<{ order: number }>>(items: T): T =>
  items.map((item, index) => ({
    ...item,
    order: index + 1,
  })) as T;

const useHighlightAgentIdForThreeSeconds = ({
  firstLoaded,
}: {
  firstLoaded: boolean;
}) => {
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const [highlightId, setHighlightId] = useState(
    getRightParams(GlobalPanelKeys.GlobalManageAgents)?.highlightAgentId
  );

  useEffect(() => {
    if (!firstLoaded) return;

    const timer = setTimeout(() => {
      setHighlightId(undefined);
    }, 3000);

    return () => clearTimeout(timer);
  }, [firstLoaded]);

  return {
    highlightId,
  };
};

export default function ManageAgentsPanel() {
  const { t } = useTranslation('agent');
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const ctaButtonRef = useRef<HTMLButtonElement>(null);
  const { club } = useCurrentIaClub();
  const [ctaButtonLoading, setCtaButtonLoading] = useState(false);
  const [shareOpened, setShareOpened] = useState(false);
  const [sharedDropdownAnchorEl, setSharedDropdownAnchorEl] =
    useState<HTMLElement | null>();
  const [selectedShareAgent, setSelectedShareAgent] =
    useState<GetIaAgentUserIconRhsAgentUserDefaultRes>();

  const { userId } = useAuth();
  const agentsData = useAgentUsers({
    viewSlug: AgentUserViewSlug.ListRhsAgentUserDefault,
    search: club
      ? `agentClub:${club.clubId};userId:${userId}`
      : `userId:${userId}`,
    searchFields: club ? 'agentClub:eq;userId:eq' : 'userId:eq',
    orderBy: 'order',
    sortBy: 'asc',
  });
  const {
    dataset,
    mutate,
    isLoadingInitialData,
    isLoading,
    isLoadingMore,
    totalCount,
    data,
  } = agentsData;
  const loaded = !isLoadingInitialData && !isLoading;
  const iaAgentAccessControl = useIaAgentAccessControl();
  const { highlightId } = useHighlightAgentIdForThreeSeconds({
    firstLoaded: !!data,
  });

  const reloadAgentUsers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: {
      ...agentsData,
      isReachingEnd: true, // FIXME: currently backend ia response has wrong 'totalCount', after backend fix, we can remove limit=0 and remove this line
    },
  });

  const layouts = useMemo(() => {
    if (!loaded)
      return [
        {
          layout: 'list-layout',
          areas: [
            {
              key: 'loading',
              areaType: 'loading',
              showIcon: true,
            },
          ],
        } as ListLayoutConfig,
      ];

    const loadingMoreItems: ListLayoutItem[] = isLoadingMore
      ? [
          {
            id: 'loading',
            loading: true,
            icon: { type: 'loading' },
          },
        ]
      : [];

    return [
      {
        layout: 'list-layout',
        areas: [
          {
            key: 'list',
            settings: {
              title: t('## Added Agents', { count: totalCount }),
              emptyText: t('No added agents'),
              sortableConfig: {
                dropAction: 'reorderAgent',
              },
            },
            areaType: 'list',
            items: [
              ...dataset.map((agentUser) => {
                const agent = agentUser.agent;

                return {
                  id: agentUser.agentUserId,
                  text: agent.agentName,
                  icon: {
                    type: 'image',
                    value:
                      agent.agentPhoto || getAgentIconPath(agent.agentName),
                  },
                  highlight: highlightId === agent.agentId,
                  hidden: agentUser.visibility === AgentVisibility.Hidden,
                  draggable: agentUser.visibility !== AgentVisibility.Hidden,
                  actionMap: {
                    click: {
                      type: 'event',
                      value: 'openAgentProfile',
                    },
                  },
                  moreActions: [
                    {
                      type: 'event',
                      icon: 'ActionArrowRight',
                      value: 'openAgentProfile',
                      text: t('Open Agent Profile'),
                      hideOnItem: true,
                    },
                    iaAgentAccessControl.isEditable(agent) && {
                      type: 'event',
                      icon: 'OtherEdit',
                      value: 'openEditAgentPage',
                      text: t('Edit Agent'),
                      hideOnItem: true,
                    },
                    iaAgentAccessControl.isDuplicatable(agent) && {
                      type: 'event',
                      icon: 'OtherCopy',
                      value: 'duplicateAgent',
                      text: t('Duplicate'),
                      hideOnItem: true,
                    },
                    {
                      type: 'event',
                      icon:
                        agentUser.visibility === AgentVisibility.Shown
                          ? 'LoginHide'
                          : 'LoginSee',
                      value:
                        agentUser.visibility === AgentVisibility.Shown
                          ? 'hideAgent'
                          : 'showAgent',
                      text:
                        agentUser.visibility === AgentVisibility.Shown
                          ? t('Hide')
                          : t('Show'),
                    },
                    {
                      type: 'event',
                      icon: 'OtherShareToDirect',
                      value: 'shareAgent',
                      text: t('Share'),
                    },
                    {
                      type: 'event',
                      icon: 'ActionClose',
                      value: 'removeMyAgent',
                      text: t('Unadd'),
                      hideOnItem: true,
                    },
                  ].filter(Boolean),
                };
              }),
              ...loadingMoreItems,
            ],
          },
        ],
      } as ListLayoutConfig,
    ];
  }, [
    highlightId,
    iaAgentAccessControl,
    isLoadingMore,
    loaded,
    dataset,
    t,
    totalCount,
  ]);

  const idToAgentUser = useMemo(
    () => keyBy(dataset, (d) => d.agentUserId),
    [dataset]
  );
  const iaAgentActions = useIaAgentActions();
  const iaAgentUserActions = useIaAgentUserAction();
  const availableActions = {
    openEditAgentPage: {
      action: ({ id }: { id: string }) => {
        const agentUser = idToAgentUser[id];
        if (!agentUser) return;
        iaAgentActions.openEditAgentPage({
          id: agentUser.agent.agentId,
          clubSlug: agentUser.agentClub.clubSlug,
        });
      },
    },
    duplicateAgent: {
      action: ({ id }: { id: string }) => {
        const agentUser = idToAgentUser[id];
        if (!agentUser) return;
        void iaAgentActions.duplicateAgent({
          id: agentUser.agent.agentId,
          clubSlug: agentUser.agentClub.clubSlug,
        });
      },
    },
    removeMyAgent: {
      action: async ({ id }: { id: string }) => {
        const agentId = idToAgentUser[id]?.agent.agentId;
        if (!agentId) return;
        void reloadAgentUsers(iaAgentActions.removeMyAgent({ id: agentId }), {
          optimisticData: dataset.filter((d) => d.agentUserId !== id),
        });
      },
    },
    openAgentProfile: {
      action: ({ id }: { id: string }) => {
        const agentId = idToAgentUser[id]?.agent.agentId;
        if (!agentId) return;
        openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId,
          returnPanel: GlobalPanelKeys.GlobalManageAgents,
        });
      },
    },
    showAgent: {
      action: async ({ id }: { id: string }) => {
        void reloadAgentUsers(iaAgentUserActions.showAgent({ id }), {
          optimisticData: dataset.map((d) =>
            d.agentUserId === id
              ? {
                  ...d,
                  visibility: AgentVisibility.Shown,
                }
              : d
          ),
        });
      },
    },
    hideAgent: {
      action: async ({ id }: { id: string }) => {
        void reloadAgentUsers(iaAgentUserActions.hideAgent({ id }), {
          optimisticData: dataset.map((d) =>
            d.agentUserId === id
              ? {
                  ...d,
                  visibility: AgentVisibility.Hidden,
                }
              : d
          ),
        });
      },
    },
    shareAgent: {
      action: ({ id }: { id: string }) => {
        const targetAnchorElement = document.querySelector(
          `[data-item-id="${id}"]`
        ) as HTMLElement | null;
        const agentUser = idToAgentUser[id];
        setSharedDropdownAnchorEl(targetAnchorElement);
        setSelectedShareAgent(agentUser);
        setShareOpened(true);
      },
    },
    reorderAgent: {
      action: (({ activeItem, overItem, newItems }) => {
        const activeAgentUser = idToAgentUser[activeItem.id];
        const overAgentUser = idToAgentUser[overItem.id];
        const newAgentUsers = newItems.map((item) => idToAgentUser[item.id]);

        if (!activeAgentUser || !overAgentUser) {
          console.warn('cannot find active or over agent user');
          return;
        }

        void reloadAgentUsers(
          iaAgentUserActions.reorderAgent({
            id: activeAgentUser.agentUserId,
            [activeAgentUser.order < overAgentUser.order
              ? 'afterItemId'
              : 'beforeItemId']: overItem.id,
          }),
          {
            optimisticData: normalizeOrder(newAgentUsers),
          }
        );
      }) as SortableOnDragOverOtherItem<{ id: string }>,
    },
  };

  const handleCreateAgentButtonClick = () => {
    if (!club?.clubSlug) return;

    setCtaButtonLoading(true);
    void Router.push(`/club/${club.clubSlug}/agent/form/settings`);
  };

  const onCloseSharedDropdown = useCallback((): void => {
    setSharedDropdownAnchorEl(null);
    setSelectedShareAgent(undefined);
    setShareOpened(false);
  }, []);

  return (
    <>
      <BaseLayoutRightPanel
        titleIcon={<ProfileManageAIBotIcon width={16} height={16} />}
        title={t('Manage Agents')}
        toolComponent={<ManageAgentsToolbar />}
        actionComponent={
          club?.clubSlug && (
            <AuthGuard>
              <EmphasizeButton
                ref={ctaButtonRef}
                sx={{ width: '100%' }}
                prefixIcon={<Icon name="TestAdd" width={16} height={16} />}
                onClick={handleCreateAgentButtonClick}
                loading={ctaButtonLoading}
              >
                {t('Create Agents')}
              </EmphasizeButton>
            </AuthGuard>
          )
        }
      >
        <BaseLayoutRightPanel.ScrollArea
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <IaActionContextProvider availableActions={availableActions}>
            <IaLayouts layouts={layouts} />
          </IaActionContextProvider>
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
      <SharedDropdown
        menuAnchorEl={sharedDropdownAnchorEl}
        open={shareOpened}
        onClose={onCloseSharedDropdown}
        virtualRouter={{
          pathname: '/agents/[agentId]',
          query: {
            agentId: selectedShareAgent?.agent.agentId ?? '',
            clubSlug: selectedShareAgent?.agentClub.clubSlug ?? '',
          },
          asPath: `/agents/${selectedShareAgent?.agent.agentId}`,
        }}
      />
    </>
  );
}
