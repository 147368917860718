import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import { Button } from '@front/ui';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue } from '../../type';

export default function PanelStartButton() {
  const [{ formId }] = useContext(CreateQuizContext);
  const { t } = useTranslation('quiz');
  const { formState } = useFormContext<CreateQuizFormValue>();
  if (!formState.isDirty) return undefined;
  return (
    <Button
      loading={formState.isSubmitting}
      sx={{ ml: { md: 'auto' } }}
      type="submit"
      form={formId}
      prefixIcon={<OtherPlayIcon />}
    >
      {t('createQuiz.buttons.start', 'Start Practice')}
    </Button>
  );
}
