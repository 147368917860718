import { MutableRefObject, useEffect } from 'react';

import { ChallengeEvents } from '../enums';
import { CreateQuizFormStatus } from '../type';

export default function useWatchFormStatus(
  eventBusRef: MutableRefObject<EventTarget>,
  formState: CreateQuizFormStatus
) {
  useEffect(() => {
    eventBusRef.current.dispatchEvent(
      new CustomEvent(ChallengeEvents.FormStatusChange, {
        detail: {
          isDirty: formState.isDirty,
          isSubmitting: formState.isSubmitting,
        },
      })
    );
  }, [formState.isDirty, formState.isSubmitting, eventBusRef]);
}
