import { NextRouter } from 'next/router';
import { VirtualRouter } from '@app/web/src/types/router';
import { ExamMode, isDev, SharedNotificationTag } from '@lib/web/apis';
import { QUIZ_SHORT_ID_LENGTH } from '@lib/web/hooks';

function extractQuizId(router: NextRouter | VirtualRouter) {
  const paramQuizId = router.query.quizId as string;
  let quizShortId = undefined;
  let quizId = undefined;
  if (paramQuizId.length === QUIZ_SHORT_ID_LENGTH) {
    quizShortId = paramQuizId;
  } else {
    quizId = paramQuizId;
  }
  return {
    quizId,
    quizShortId,
  };
}

function extractQuestionNumber(router: NextRouter | VirtualRouter) {
  return router.asPath.split('#')[1];
}

/**
 *
 * @param router NextRouter
 * @returns { tag: SharedNotificationTag, extraObj?: SharePageReq['extraObj'] }
 *
 *
 * NOTI-269 Get Share View Only - My Profile
 * TODO: NOTI-272 Get Share View Only - Other User’s My Profile
 * TODO: NOTI-280 Get Share View Only - Other User’s Analytics
 * TODO: NOTI-283 Get Share View Only - Inside of Question Editor
 * TODO: NOTI-285 Get Share View Only - Other User’s Inside of Question Editor
 * TODO: NOTI-287 Get Share View Only - Inside of Review Questions
 * TODO: NOTI-289 Get Share View Only - Other User’s Inside Review Questions
 * NOTI-290 Get Share View Only - Get Share View Only - Inside of Turbo - Question
 * TODO: NOTI-291 Get Share View Only - Other User’s Inside of Turbo - Results
 * NOTI-292 Get Share View Only - Inside of Mock - Question
 * TODO: NOTI-294 Get Share View Only - Discover Club Groups
 * TODO: NOTI-295 Get Share View Only - Discover Club
 * TODO: NOTI-296 Get Share View Only - Discover Agents
 * TODO: NOTI-297 Get Share View Only - Club Group Detail page
 * TODO: NOTI-298 Get Share View Only - Club Detail page
 * TODO: NOTI-299 Get Share View Only - Agent Detail page
 * TODO: NOTI-300 Get Share View Only - Rankings
 * TODO: NOTI-301 Get Share View Only - Other User’s Rankings
 * TODO: NOTI-302 Get Share View Only - Get Share View Only - Playlists History
 * TODO: NOTI-303 Get Share View Only - Other User’s Playlists History
 * TODO: NOTI-304 Get Share View Only - Playlists Saved
 * TODO: NOTI-305 Get Share View Only - Other User’s Playlists Saved
 * TODO: NOTI-306 Get Share View Only - Playlists Emoji
 * TODO: NOTI-307 Get Share View Only - Other User’s Playlists Emoji
 * TODO: NOTI-330 Get Share View Only - Playlists Followings’
 * TODO: NOTI-333 Get Share View Only - Other User’s Playlists Followings’
 * TODO: NOTI-335 Get Share View Only - Playlists Hashtags
 * TODO: NOTI-336 Get Share View Only - Other User’s Playlists Hashtags
 * TODO: NOTI-339 Get Share View Only - Playlists Creators’
 * TODO: NOTI-341 Get Share View Only - Open Challenges
 * TODO: NOTI-343 Get Share View Only - Invited (Challenge) page
 * TODO: NOTI-345 Get Share View Only - Other User’s Invited (Challenge) page
 * TODO: NOTI-347 Get Share View Only - Ongoing (Challenge) page
 * TODO: NOTI-349 Get Share View Only - Other User’s Ongoing (Challenge) page
 * TODO: NOTI-352 Get Share View Only - Expired (Challenge) page
 * TODO: NOTI-354 Get Share View Only - Other User’s Expired (Challenge) page
 * TODO: NOTI-356 Get Share View Only - My Questions
 * TODO: NOTI-358 Get Share View Only - Other User’s My Questions
 * TODO: NOTI-360 Get Share View Only - Review Questions page
 * TODO: NOTI-362 Get Share View Only - Other User’s Review Questions page
 * TODO: NOTI-364 Get Share View Only - Inside of Review Questions - Person
 * TODO: NOTI-366 Get Share View Only - Review Questions - All Questions
 * TODO: NOTI-368 Get Share View Only - Other User’s All Questions
 * TODO: NOTI-370 Get Share View Only - Review Questions - Person
 * TODO: NOTI-428 Get Share View Only - Club Agents page
 * TODO: NOTI-430 Get Share View Only - My Agents page
 * TODO: NOTI-432 Get Share View Only - Other User’s My Agents page
 * TODO: NOTI-434 Get Share View Only - Other User’s Mock Results
 */
export function getShareSettingsByPage(router: NextRouter | VirtualRouter): {
  page: string;
  params: {
    tag: SharedNotificationTag;
    clubSlug?: string;
    extraInfo?: SharePageReq['extraInfo'];
  };
} | null {
  const clearPathname = router.pathname.replace('/shared/[sid]', '');
  // NOTI-269 Get Share View Only - My Profile
  if (clearPathname === '/profile/account') {
    return {
      page: 'Profile',
      params: {
        tag: SharedNotificationTag.ShareProfile,
      },
    };
  }

  // NOTI-290 Get Share View Only - Get Share View Only - Inside of Turbo - Question
  if (clearPathname === '/club/[slug]/practice/[quizId]/[roundNo]') {
    const { quizId, quizShortId } = extractQuizId(router);
    return {
      page: 'Practice',
      params: {
        tag: SharedNotificationTag.SharePracticeQuestion,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.Practice),
          questionIndex: extractQuestionNumber(router),
        },
      },
    };
  }

  // NOTI-292 Get NOTI-292 Get Share View Only - Inside of Mock - Question
  if (isDev && clearPathname === '/club/[slug]/exam/[quizId]/[roundNo]') {
    const { quizId, quizShortId } = extractQuizId(router);
    return {
      page: 'Mock Exam',
      params: {
        tag: SharedNotificationTag.SharePracticeQuestion,
        extraInfo: {
          quizId,
          quizShortId,
          roundNo: router.query.roundNo as string,
          mode: String(ExamMode.MockExam),
          questionIndex: extractQuestionNumber(router),
        },
      },
    };
  }
  // NOTI-321 Share Agent Detail page
  if (clearPathname === '/club/[slug]/agents/[agentId]') {
    const { query } = router;
    return {
      page: 'Club Agent Detail',
      params: {
        tag: SharedNotificationTag.SharedClubAgent,
        clubSlug: query.clubSlug as string,
        extraInfo: {
          agentId: query.agentId as string,
        },
      },
    };
  }
  //  NOTI-316 Share Discover Agents
  if (clearPathname === '/agents/[agentId]') {
    const { query } = router;
    return {
      page: 'Agent Detail',
      params: {
        tag: SharedNotificationTag.SharedClubAgent,
        clubSlug: query.clubSlug as string,
        extraInfo: {
          agentId: query.agentId as string,
        },
      },
    };
  }

  return null;
}

// quizId?: string;
// agentId?: string;
// challengeId?: string;
// creatorQuestionId?: string;
// roundNo?: string;
// questionIndex?: string;
// clubGroupId?: string;
// reviewerUserId?: string;
// group?: string;
// title?: string;
// sourceId?: string;
// avatarName?: string;
// productId?: string;
