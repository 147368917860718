import { useCallback, useEffect, useState } from 'react';
import { ThreadPanelKeys, ThreadPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { apis } from '@lib/web/apis';
import { useEmojiSource } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { callWithToast } from '@lib/web/utils';

type UseThreadViewChangeEmojiProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

type UseThreadViewChangeEmojiReturn = {
  emoji?: GetMarkEmojiRes;
  handleOpenEmojiPanel: () => void;
};

const useThreadViewChangeEmoji = ({
  view,
  clubId,
}: UseThreadViewChangeEmojiProps): UseThreadViewChangeEmojiReturn => {
  const { reloadViews } = useClubThreadViews({ clubId });

  const { emojiSource } = useEmojiSource();
  const { viewIcon } = useThreadViewDetail(view);
  const [currEmoji, setCurrEmoji] = useState<GetMarkEmojiRes>();

  const { openRightPanel } = useBaseRightPanel<ThreadPanelParams>();

  const handleChangeEmoji = useCallback(
    async ({
      changedEmoji,
    }: {
      changedEmoji: GetMarkEmojiRes;
    }): Promise<void> => {
      const payload: SetThreadViewReq = {
        type: view.type,
        threadViewUserId: view.threadViewUsers[0].id,
        emoji: changedEmoji.code,
      };
      await callWithToast(apis.thread.setThreadView(payload), {
        showLoading: false,
      });
      setCurrEmoji(changedEmoji);
      reloadViews();
    },
    [reloadViews, view.threadViewUsers, view.type]
  );

  const handleOpenEmojiPanel = useCallback((): void => {
    const targetEmoji = emojiSource.find(
      (emoji) => emoji.code === view.threadViewUsers[0].customEmoji
    );
    openRightPanel(ThreadPanelKeys.ThreadEmoji, {
      emojiId: targetEmoji?.id ?? '',
      emojiCode: targetEmoji?.code ?? '',
      emojiName: targetEmoji?.name ?? '',
      onChange: handleChangeEmoji,
    });
  }, [emojiSource, handleChangeEmoji, openRightPanel, view.threadViewUsers]);

  useEffect(() => {
    setCurrEmoji(emojiSource.find((e) => e.code === viewIcon?.emoji));
  }, [emojiSource, viewIcon]);

  return { emoji: currEmoji, handleOpenEmojiPanel };
};

export default useThreadViewChangeEmoji;
