import { createContext, FC, ReactNode, useMemo, useState } from 'react';
import { QuizGroupType } from '@lib/web/apis';

type FilterType = null | 'mistakes' | 'overtime' | 'emoji';
export type PlaylistDetailContextType = {
  quizId: string;
  markId: string;
  tagId?: string;
  roundNo: number;
  type:
    | 'default'
    | 'creators'
    | 'overtime'
    | 'mistakes'
    | 'emoji'
    | 'hashtags'
    | 'challenge';
  sid?: string;
  dynamicType?: QuizGroupType;
  sectionId?: string;
  customDate?: string;
  isFilterMistake: boolean;
  isFilterOvertime: boolean;
  isFilterEmoji: boolean;
  isCommon: boolean;
  toggleFilter: (ev: FilterType) => void;
  setFilter: (ev: FilterType, isFiltered: boolean) => void;
};

export const PlaylistDetailContext = createContext<PlaylistDetailContextType>({
  quizId: '',
  markId: '',
  sectionId: '',
  customDate: '',
  roundNo: 0,
  type: 'default',
  sid: undefined,
  dynamicType: undefined,
  isFilterMistake: false,
  isFilterOvertime: false,
  isFilterEmoji: false,
  isCommon: false,

  toggleFilter: () => {},
  setFilter: () => {},
});

type PlaylistDetailProviderProps = Partial<PlaylistDetailContextType> & {
  children: ReactNode;
  roundNo?: number;
};

export const PlaylistDetailProvider: FC<PlaylistDetailProviderProps> = ({
  children,
  quizId,
  markId,
  tagId,
  roundNo = 0,
  sectionId,
  customDate,
  dynamicType,
  type,
  sid,
  isFilterMistake: defaultIsFilterMistake,
  isFilterOvertime: defaultIsFilterOvertime,
  isFilterEmoji: defaultIsFilterEmoji,
  isCommon = false,
}) => {
  const [isFilterMistake, setIsFilterMistake] = useState(
    !!defaultIsFilterMistake
  );
  const [isFilterOvertime, setIsFilterOvertime] = useState(
    !!defaultIsFilterOvertime
  );
  const [isFilterEmoji, setIsFilterEmoji] = useState(!!defaultIsFilterEmoji);

  const memoizedValue = useMemo(() => {
    const value = {
      quizId: quizId ?? '',
      markId: markId ?? '',
      tagId,
      roundNo: roundNo ?? 0,
      type: type ?? 'default',
      sid,
      dynamicType,
      sectionId,
      customDate,
      isFilterMistake,
      isFilterOvertime,
      isFilterEmoji,
      isCommon,
      toggleFilter: (updateValue: FilterType) => {
        if (updateValue === null) {
          setIsFilterMistake(false);
          setIsFilterOvertime(false);
          setIsFilterEmoji(false);
        } else if (updateValue === 'emoji') {
          setIsFilterEmoji((st) => !st);
        } else if (updateValue === 'mistakes') {
          setIsFilterMistake((st) => !st);
        } else if (updateValue === 'overtime') {
          setIsFilterOvertime((st) => !st);
        }
      },
      setFilter: (updateValue: FilterType, isFiltered: boolean) => {
        if (updateValue === 'emoji') {
          setIsFilterEmoji(isFiltered);
        } else if (updateValue === 'mistakes') {
          setIsFilterMistake(isFiltered);
        } else if (updateValue === 'overtime') {
          setIsFilterOvertime(isFiltered);
        }
      },
    };
    return value;
  }, [
    quizId,
    markId,
    tagId,
    roundNo,
    type,
    dynamicType,
    sid,
    sectionId,
    customDate,
    isFilterMistake,
    isFilterOvertime,
    isFilterEmoji,
    isCommon,
  ]);

  return (
    <PlaylistDetailContext.Provider value={memoizedValue}>
      {children}
    </PlaylistDetailContext.Provider>
  );
};
