import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Theme } from '@mui/material';
import usePlaylistSummary from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';
import { testIdConfig } from '@front/config';
import {
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import { Button, TextButton, useBaseRightPanel } from '@front/ui';
import { QuizType } from '@lib/web/apis';
import { useClubSlug, useIaClubStatus } from '@lib/web/hooks';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

const styles = {
  root: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    mt: 'auto',
    mx: '-12px',
    py: 2.5,
    px: '12px',
    background: (theme: Theme) => theme.palette.background.body,
    backgroundSize: '100vw 100vh',
    backgroundPosition: 'right bottom',
    backgroundAttachment: 'fixed',
    justifyContent: { md: 'flex-end' },
  },
  reset: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    flex: 1,
    maxWidth: { md: 144 },
    px: 0,
  },
};
export default function BottomActions() {
  const { t } = useTranslation('quiz');
  const { reset, formState, watch } = useFormContext<CreateQuizFormValue>();
  const [
    {
      panelKeyPrefix,
      availableQuestionCount,
      challengeMode,
      clubSlug: providedClubSlug,
    },
  ] = useContext(CreateQuizContext);
  const { data: quizSummary } = usePlaylistSummary();
  const routerClubSlug = useClubSlug();
  const clubSlug = providedClubSlug || routerClubSlug;
  const { inactive } = useIaClubStatus(clubSlug);
  const { openRightPanel } = useBaseRightPanel();

  /**
   * Creators playlist is not limited by approved questions count because they don't need to be approved
   */
  const disabled =
    quizSummary?.data.quiz.type === QuizType.CreatorUserQuiz
      ? inactive
      : inactive || !availableQuestionCount;

  const challengerValue = watch('challengers', []);
  const randomChallengerCount = watch('randomChallengerCount', 0);

  const hasChallengers =
    challengerValue.length > 0 || randomChallengerCount > 0;
  return (
    <Box sx={styles.root}>
      <Box sx={[styles.reset, styles.button]}>
        <TextButton
          onClick={() => reset(formState.defaultValues)}
          disabled={!formState.isDirty || formState.isSubmitting}
        >
          {t('createQuiz.buttons.reset', 'Reset')}
        </TextButton>
      </Box>
      {challengeMode && !hasChallengers ? (
        <Button
          sx={styles.button}
          loading={formState.isSubmitting}
          disabled={!clubSlug || disabled}
          data-testid={testIdConfig.startPractice}
          onClick={() =>
            openRightPanel(`${panelKeyPrefix}${CreateQuizPanelKeys.Challenger}`)
          }
          prefixIcon={<OtherFriendsChallengeIcon />}
        >
          {t('createQuiz.buttons.addChallengers', 'Add Challengers')}
        </Button>
      ) : (
        <Button
          sx={styles.button}
          type="submit"
          loading={formState.isSubmitting}
          disabled={!clubSlug || disabled}
          data-testid={testIdConfig.startPractice}
          prefixIcon={<OtherPlayIcon />}
        >
          {hasChallengers
            ? t('createQuiz.buttons.startChallenge', 'Start Challenge')
            : t('createQuiz.buttons.start', 'Start Practice')}
        </Button>
      )}
    </Box>
  );
}
