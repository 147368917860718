import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import {
  ActionSearch as ActionSearchIcon,
  OtherSort as OtherSortIcon,
} from '@front/icon';
import { getPropertyIcon, TextField } from '@front/ui';

import Icon from '../../components/Icon';
import { SortField, SortFieldsConfig } from '../types';

const styles = {
  root: {
    pt: 1,
  },
  filterInput: {
    px: '12px',
  },
  fields: {
    display: 'grid',
  },
  field: {
    px: '22px',
    py: { xs: '10.5px', md: '3.5px' },
    width: '100%',
  },
  selectedField: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  fieldInner: {
    width: '100%',
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  fieldIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldLabel: {
    fontSize: { xs: 16, md: 14 },
    fontWeight: 400,
  },
  empty: {
    px: '12px',
  },
  emptyText: {
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.64,
  },
};

type SortFieldItemProps = {
  field: SortField;
  onClick: () => void;
  selected?: boolean;
  onMouseEnter?: (ev: MouseEvent<HTMLButtonElement>) => void;
};
function SortFieldItem({
  field,
  onClick,
  selected,
  onMouseEnter,
}: SortFieldItemProps) {
  return (
    <ButtonBase
      sx={[styles.field, !!selected && styles.selectedField]}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Box sx={styles.fieldInner}>
        <Typography sx={styles.fieldIcon}>
          {field.customIcon ? (
            <Icon name={field.customIcon} width={16} height={16} />
          ) : (
            getPropertyIcon(field.type, 16)
          )}
        </Typography>
        <Typography sx={styles.fieldLabel}>{field.label}</Typography>
      </Box>
    </ButtonBase>
  );
}

type SortFieldListProps = {
  fields: SortField[];
  onSelect: (item: SortField) => void;
  filterKeywords: string;
};
function SortFieldList({
  fields,
  onSelect,
  filterKeywords,
}: SortFieldListProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [filterKeywords]);

  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      if (selectedIndex === -1) {
        return;
      }
      if (selectedIndex + 1 < fields.length) {
        setSelectedIndex(selectedIndex + 1);
      }
    },
    [selectedIndex, fields],
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    }
  );

  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      if (selectedIndex === -1) {
        return;
      }
      if (selectedIndex - 1 >= 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    },
    [selectedIndex],
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    }
  );

  useHotkeys(
    'enter',
    (ev) => {
      ev.preventDefault();
      if (selectedIndex >= 0 && selectedIndex < fields.length) {
        onSelect(fields[selectedIndex]);
      }
    },
    [selectedIndex, fields],
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    }
  );

  const handleMouseEnter = (index: number) => {
    setSelectedIndex(index);
  };

  if (fields.length === 0) {
    return (
      <Box sx={styles.empty}>
        <Typography sx={styles.emptyText}>No results found</Typography>
      </Box>
    );
  }

  const filteredItems = fields.filter(
    (item) =>
      !filterKeywords ||
      item.label.toLowerCase().includes(filterKeywords.toLowerCase()) ||
      item.name.toLowerCase().includes(filterKeywords.toLowerCase())
  );

  if (filteredItems.length === 0) {
    return (
      <Box sx={styles.empty}>
        <Typography sx={styles.emptyText}>No results found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.fields}>
      {filteredItems.map((item, index) => (
        <SortFieldItem
          field={item}
          key={index}
          onClick={() => onSelect(item)}
          selected={selectedIndex === index}
          onMouseEnter={() => handleMouseEnter(index)}
        />
      ))}
    </Box>
  );
}

type SortFieldsProps = SortFieldsConfig & {
  onSelect?: (field: SortField) => void;
  autoFocus?: boolean;
  focusDelay?: number;
};
export default function SortFields({
  fields = [],
  excludeNames = [],
  onSelect = () => {},
  autoFocus,
  focusDelay = 0,
}: SortFieldsProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [filterKeywords, setFilterKeywords] = useState('');

  const sortFields = fields.filter(
    (field) => !excludeNames.includes(field.name)
  );

  useEffect(() => {
    let timeout: NodeJS.Timer;
    if (autoFocus) {
      timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, focusDelay);
    }

    return () => clearTimeout(timeout);
  }, [autoFocus, focusDelay]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.filterInput}>
        <TextField
          label="Sort By"
          labelIcon={<ActionSearchIcon width={12} height={12} />}
          name="sortBy"
          value={filterKeywords}
          fullWidth
          size="rwd"
          placeholder="Sort by..."
          prefix={<OtherSortIcon width={16} height={16} />}
          onChange={(e) => setFilterKeywords(e.target.value)}
          inputRef={inputRef}
        />
      </Box>
      <SortFieldList
        fields={sortFields}
        onSelect={onSelect}
        filterKeywords={filterKeywords}
      />
    </Box>
  );
}
