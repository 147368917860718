import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Chip, ChipProps } from '@mui/material';
import {
  ActionClose as ActionCloseItem,
  ChatHashtag as ChatHashtagIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  ResponsiveTooltip,
  TooltipList,
  TooltipListItem,
  useBaseRightPanel,
} from '@front/ui';
import { useAnalyticsWeaknessTopic } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { shortName } from '@lib/web/utils';
import { uniq } from 'lodash';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';

const styles = {
  root: {
    minWidth: 0,
    display: 'flex',
    gap: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    pt: 1,
  },
  iconChip: {
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  moreTooltipItems: {
    gridTemplateColumns: { xs: '76px 1fr', md: '76px 100px' },
    workBreak: 'break-all',
    '& .tooltip-list-item-name': {
      whiteSpace: 'unset',
    },
  },
  moreTooltipItemValue: {
    textAlign: 'right',
  },
};

const assignToTagFormat = (
  item: GetAnalyticsWeaknessTopicItemRes,
  option: {
    color: ChipProps['color'];
    type: 'mistake' | 'overtime' | 'notPracticed';
    icon: string;
  }
) => {
  const display = item.tagName;
  const shortDisplay = shortName(display, 28);
  return {
    ...option,
    display,
    shortDisplay,
    count: item.questionCount,
    total: `${item.totalCount.toFixed(0)}`,
  };
};

const iconMap = {
  mistake: 'ActionCloseThick',
  overtime: 'TestClockSolid',
  notPracticed: 'OtherOutOfPractice',
};

type OrganizedTagType = {
  display: string;
  shortDisplay: string;
  count: number;
  total: string;
  color: ChipProps['color'];
  type: 'mistake' | 'overtime' | 'notPracticed';
  icon: string;
};
export default function TopicCard() {
  const { t } = useTranslation('quiz');
  const { openRightPanel } = useBaseRightPanel();
  const [{ panelKeyPrefix, disabledParts, clubSlug }] =
    useContext(CreateQuizContext);
  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const tags = watch('tags', []);
  const panelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.Topic}`;
  const disabled =
    disabledParts.includes('all') || disabledParts.includes('topics');
  const { sectionId } = useCurrentIaClub(clubSlug);
  const { data: weaknessTopicData } = useAnalyticsWeaknessTopic(sectionId);

  const organizedTags = useMemo(() => {
    if (!weaknessTopicData) return [];
    const result: OrganizedTagType[] = [];

    const selectedMistake = weaknessTopicData.data.mistake.filter((item) =>
      tags.includes(item.tagCode)
    );
    const selectedOvertime = weaknessTopicData.data.overtime.filter((item) =>
      tags.includes(item.tagCode)
    );
    const selectedNotPracticed = weaknessTopicData.data.notPracticedYet.filter(
      (item) => tags.includes(item.tagCode)
    );
    selectedMistake.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'error',
          type: 'mistake',
          icon: iconMap.mistake,
        })
      );
    });
    selectedOvertime.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'warning',
          type: 'overtime',
          icon: iconMap.overtime,
        })
      );
    });
    selectedNotPracticed.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'primary',
          type: 'notPracticed',
          icon: iconMap.notPracticed,
        })
      );
    });

    return result;
  }, [weaknessTopicData, tags]);

  const mistakeCodes = useMemo(() => {
    if (!weaknessTopicData) return [];
    return weaknessTopicData.data.mistake.map((item) => item.tagCode);
  }, [weaknessTopicData]);

  const restCount = organizedTags.length > 3 ? organizedTags.length - 3 : 0;
  const isSelectAllMistake =
    mistakeCodes.every((code) => tags.includes(code)) && tags.length > 0;

  const removeAllMistake = () => {
    if (!weaknessTopicData) return;
    setValue(
      'tags',
      tags.filter((tagCode) => !mistakeCodes.includes(tagCode)),
      { shouldDirty: true }
    );
  };
  const toggleSelectAllMistakes = () => {
    if (!weaknessTopicData) return;
    if (isSelectAllMistake) {
      removeAllMistake();
    } else {
      setValue('tags', uniq(tags.concat(mistakeCodes)), { shouldDirty: true });
    }
  };

  const renderMoreListItems = useMemo(() => {
    if (organizedTags.length <= 3) return undefined;
    const renderTags = organizedTags.slice(3).reduce(
      (acc, tag) => {
        return { ...acc, [tag.type]: [...acc[tag.type], tag] };
      },
      {
        mistake: [] as OrganizedTagType[],
        overtime: [] as OrganizedTagType[],
        notPracticed: [] as OrganizedTagType[],
      }
    );
    return Object.keys(renderTags).map((typeKey) => {
      const tagsText = renderTags[typeKey as keyof typeof renderTags].map(
        (tag) => `#${tag.display} (${tag.count})`
      );
      if (tagsText.length === 0) return undefined;
      return (
        <TooltipListItem
          key={typeKey}
          name={{
            icon: iconMap[typeKey as keyof typeof renderTags],
            text: t(`createQuiz.topic.customize.property.${typeKey}`),
          }}
          sx={styles.moreTooltipItems}
          value={{
            sxProps: styles.moreTooltipItemValue,
            type: 'element',
            value: (
              <Box>
                {tagsText.map((tagText, i) => (
                  <Box key={tagText}>
                    {tagText}
                    {i !== tagsText.length - 1 && ','}
                  </Box>
                ))}
              </Box>
            ),
          }}
        />
      );
    });
  }, [organizedTags, t]);
  return (
    <Section title={t('createQuiz.topic.title', 'Topic')}>
      <Box sx={styles.root}>
        {isSelectAllMistake && (
          <Chip
            sx={styles.iconChip}
            variant="outlined"
            label={<ActionCloseItem width={16} height={16} />}
            onClick={removeAllMistake}
          />
        )}
        {!!weaknessTopicData?.data.mistake.length && (
          <ResponsiveTooltip
            title={t('createQuiz.topic.selectAll.title', 'Select All Mistakes')}
            content={t(
              'createQuiz.topic.selectAll.hint.content',
              'Select all topics where you’ve made mistakes to practice questions specifically tagged for focused practice.'
            )}
            tooltipProps={{ followCursor: true }}
          >
            <Chip
              variant={isSelectAllMistake ? 'filled' : 'outlined'}
              label={t(
                'createQuiz.topic.selectAll.title',
                'Select All Mistakes'
              )}
              clickable
              disabled={disabled}
              onClick={toggleSelectAllMistakes}
            />
          </ResponsiveTooltip>
        )}
        <ResponsiveTooltip
          titleIcon={<TestAddIcon width={16} height={16} />}
          title={t('createQuiz.topic.customize.hint.title', 'Customize Topics')}
          content={t(
            'createQuiz.topic.customize.hint.content',
            'Select topics to filter and practice questions specifically tagged for focused practice.'
          )}
          tooltipProps={{ followCursor: true }}
        >
          <Chip
            variant="dashed"
            label={t('createQuiz.topic.customize.title')}
            icon={<TestAddIcon width={16} height={16} />}
            clickable
            disabled={disabled}
            onClick={() => openRightPanel(panelKey)}
          />
        </ResponsiveTooltip>
      </Box>
      {organizedTags.length > 0 && (
        <Box sx={styles.chips}>
          {organizedTags.slice(0, 3).map((tag) => (
            <ResponsiveTooltip
              key={`${tag.type} ${tag.display}`}
              title={`#${tag.shortDisplay}`}
              content={
                <TooltipList
                  content={t('createQuiz.topic.customize.hint.content', {
                    tag: `#${tag.shortDisplay}`,
                    context: tag.type,
                  })}
                >
                  {tag.count !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: tag.icon,
                        text: t(
                          `createQuiz.topic.customize.property.${tag.type}`
                        ),
                      }}
                      value={{
                        type: 'text',
                        text: tag.count ? `${tag.count}` : '',
                      }}
                    />
                  )}
                  {tag.total !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: 'ChatQuestionList',
                        text: t(
                          'createQuiz.topic.customize.property.totalQuestion',
                          'Total Questions'
                        ),
                      }}
                      value={{ type: 'text', text: `${tag.total}` }}
                    />
                  )}
                </TooltipList>
              }
              tooltipProps={{ followCursor: true }}
            >
              <Chip
                variant="filled"
                color={tag.color}
                clickable
                label={
                  tag.count === undefined
                    ? `#${tag.display}`
                    : `#${tag.display}(${tag.count})`
                }
                onClick={() => openRightPanel(panelKey)}
                disabled={disabled}
              />
            </ResponsiveTooltip>
          ))}
          {restCount > 0 && (
            <ResponsiveTooltip
              titleIcon={<ChatHashtagIcon width={16} height={16} />}
              title={t('createQuiz.topic.title')}
              content={<TooltipList>{renderMoreListItems}</TooltipList>}
              tooltipProps={{ followCursor: true }}
            >
              <Chip
                variant="filled"
                color="default"
                clickable
                label={`+ ${restCount} more`}
                onClick={() => openRightPanel(panelKey)}
                disabled={disabled}
              />
            </ResponsiveTooltip>
          )}
        </Box>
      )}
    </Section>
  );
}
