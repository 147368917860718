import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Portal } from '@mui/material';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import {
  ActionClear as ActionClearIcon,
  NFTFavorite as NFTFavoriteIcon,
  OtherDelete as OtherDeleteIcon,
  OtherFilter as OtherFilterIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { getPropertyIcon, TipButton, TitleBar } from '@front/ui';

import FilterContext from '../context/FilterContext';
import useFilterActions from '../hooks/useFilterActions';

import FilterContent from './FilterContent';

const styles = {
  root: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.darker',
    zIndex: 'modal',
  },
  bar: {
    px: 2.5,
  },
  title: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
  },
  titleBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    position: 'relative',
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ml: 'auto',
  },
  toolbarButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    position: 'absolute',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    bgcolor: 'error.dark',
    top: '-3px',
    right: '-3px',
  },
};

function isQuerySummaryPage(page?: string) {
  return !page || page === 'querySummary';
}

export default function FilterPanel({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { reset, back } = useFilterActions();

  const [settings] = useContext(FilterContext);
  const { page, selectedCondition, showEmojiPicker, history, scope } = settings;

  const { removeCondition, resetFilter, hasFilter, resetFilterDisabled } =
    useFilter(scope);

  const handleDeleteClick = () => {
    if (selectedCondition && selectedCondition.id) {
      removeCondition(selectedCondition.id);
    }
    back();
  };

  const handleResetClick = () => {
    resetFilter();
    reset();
  };

  let title;
  let titleIcon;
  if (isQuerySummaryPage(page)) {
    title = t('Filter');
    titleIcon = (
      <Box sx={styles.filterIcon}>
        <OtherFilterIcon width={16} height={16} />
        {hasFilter && <Box component="span" sx={styles.dot} />}
      </Box>
    );
  } else if (page === 'fieldList') {
    title = t('Add Filter');
    titleIcon = <TestAddIcon width={16} height={16} />;
  } else if (page === 'conditionDetail' && !showEmojiPicker) {
    title = selectedCondition?.field.label;
    titleIcon = getPropertyIcon(selectedCondition?.field.type, 18);
  } else if (page === 'conditionDetail' && showEmojiPicker) {
    title = t('Filter by Emoji');
    titleIcon = <NFTFavoriteIcon width="16" height="16" />;
  }

  let toolComponent;
  if (page === 'conditionDetail') {
    toolComponent = (
      <TipButton
        title={t('toolbar.Delete filter')}
        sx={styles.toolbarButton}
        onClick={handleDeleteClick}
      >
        <OtherDeleteIcon />
      </TipButton>
    );
  } else if (isQuerySummaryPage(page) && hasFilter) {
    toolComponent = (
      <TipButton
        title={t('toolbar.Clear all')}
        sx={styles.toolbarButton}
        disabled={resetFilterDisabled}
        onClick={handleResetClick}
      >
        <ActionClearIcon />
      </TipButton>
    );
  }

  return (
    <Portal>
      <Box className="right-panel-ignore" sx={styles.root}>
        <TitleBar
          sx={styles.bar}
          title={
            <Box sx={styles.title}>
              {titleIcon}
              <Box sx={styles.titleBox}>{title}</Box>
            </Box>
          }
          backIcon={history.currentIndex > 0 ? 'back' : 'collapse'}
          onBackClick={history.currentIndex > 0 ? back : onClose}
          toolComponent={<Box sx={styles.toolbar}>{toolComponent}</Box>}
        />
        <Box sx={styles.content}>
          <FilterContent />
        </Box>
      </Box>
    </Portal>
  );
}
