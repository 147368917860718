import { useCallback } from 'react';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { callWithToast } from '@lib/web/utils';
import { uniq } from 'lodash';
import { Channel } from 'stream-chat';

import { getNewMentionMemberIdsFromBlocks } from '../../ThreadTextComposer';
import { StreamChatGenerics } from '../../types';
import { useCreateNewChannelWithMessage } from '../channel/useCreateNewChannelWithMessage';
import { useCurrentThreadView } from '../view/useCurrentThreadView';
import { useMaybeRedirectUserToNewDmView } from '../view/useMaybeRedirectUserToNewDmView';
import { useThreadViewDetail } from '../view/useThreadViewDetail';

export const useEditMessage = () => {
  const { member } = useAuth();
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const view = useCurrentThreadView();
  const { channelData } = useThreadViewDetail(view);
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const myMemberId = member?.memberId || '';

  const editMessage = useCallback(
    async ({
      text,
      blocks,
      channel,
      messageId,
    }: {
      text: string;
      blocks: ComposerBlock[];
      channel: Channel<StreamChatGenerics>;
      messageId: string;
    }) => {
      if (!channel.data || !channel.id) {
        return;
      }

      const mentionMemberIds = getNewMentionMemberIdsFromBlocks(blocks);
      const channelMemberIds = channel.data.customActiveMembers ?? [];
      const messageMemberIds = uniq([...channelMemberIds, ...mentionMemberIds]);
      if (
        channel.data.type === 'team' &&
        messageMemberIds.length !== channelMemberIds.length
      ) {
        const newChannelResult = await createNewChannelWithMessage({
          message: text,
          blocks,
          sendPublicly: false,
          channelData,
        });
        if (newChannelResult?.viewAfterId) {
          await maybeRedirectUserToNewDmView({
            channel: newChannelResult.newChannel,
            newViewId: newChannelResult.viewAfterId,
          });
        }
      } else {
        await callWithToast(
          apis.func.threadEditMessage({
            channelType: channel.type as ThreadEditMessage['channelType'],
            channelId: channel.id,
            messageId,
            requestUserId: myMemberId,
            content: text,
            customBlocks: blocks,
          })
        );
      }
    },
    [
      channelData,
      createNewChannelWithMessage,
      maybeRedirectUserToNewDmView,
      myMemberId,
    ]
  );

  return { editMessage };
};
