import { useRouter } from 'next/router';
import { useCurrentIaClub } from '@lib/web/hooks';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';

export const useCurrentThreadView = () => {
  const { club } = useCurrentIaClub();
  const router = useRouter();
  const viewId = router.query.viewId as string;

  const { findDmView } = useDirectMessageViews();
  const { views: clubViews } = useClubThreadViews({ clubId: club?.clubId });

  return findDmView(viewId) || clubViews.find((v) => v.id === viewId);
};
