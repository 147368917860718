import { KeyboardEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { FilterField } from '../../types';

const styles = {
  multiline: {
    '& .MuiInputBase-input': {
      minHeight: '135px',
    },
  },
};

type TextFilterProps = {
  item: FilterField;
  multiline?: boolean;
};
export default function TextFilter({ item, multiline }: TextFilterProps) {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: item.name,
    rules: { required: true },
  });
  const { getIaAction } = useIaAction();
  const onEnter = getIaAction('onEnter');

  const handleInputKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      onEnter?.action();
    }
  };

  return (
    <TextField
      labelIcon={null}
      fullWidth
      size="rwd"
      placeholder={item.placeholder || 'Type a value...'}
      multiline={multiline}
      sx={[!!multiline && styles.multiline]}
      onChange={onChange}
      value={value ?? ''}
      autoFocus
      onKeyDown={handleInputKeyDown}
    />
  );
}
