import { useMemo } from 'react';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers';
import { useAuth, useSearchMemberList, useUserFollowing } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';

const apiDataToIconListLayoutItemObj = (
  data: GetFriendUserRes | GetFollowerRes,
  preselectedMemberIds: string[] = [],
  preselectedDescription?: string
): ThreadSearchUserIconListLayoutItemObj => {
  const threadMemberId = data.memberId;

  return {
    id: data.userId,
    title: data.displayName,
    titleAction: { type: 'event' as const, value: 'userTitleClick' },
    description: preselectedMemberIds.includes(threadMemberId)
      ? preselectedDescription
      : `@${data.userName}`,
    avatarUrl: data.nftAvatar || data.avatar,
    actionMap: {
      select: {
        value: 'itemSelected',
      },
      hover: {
        value: 'itemHovered',
      },
    },
    indicators: getIndicators(data.indicator),
    metadata: {
      category: 'user',
      threadMemberIds: [threadMemberId],
    },
  };
};

export const useSearchUsers = ({
  isSearching,
  debouncedSearch,
  preselectedMemberIds,
  preselectedDescription,
}: {
  isSearching: boolean;
  debouncedSearch: string;
  preselectedMemberIds?: string[];
  preselectedDescription?: string;
}) => {
  const { member } = useAuth();

  const { dataset: initialApiDataset, isLoading: initialApiLoading } =
    useUserFollowing(member?.userId || '');

  const { dataset: searchingApiDataset, isLoading: searchingApiLoading } =
    useSearchMemberList(isSearching ? debouncedSearch : '', 50);

  const initialDataset = useMemo(
    () =>
      initialApiDataset.map((data) =>
        apiDataToIconListLayoutItemObj(
          data,
          preselectedMemberIds,
          preselectedDescription
        )
      ),
    [initialApiDataset, preselectedDescription, preselectedMemberIds]
  );
  const searchingDataset = useMemo(
    () =>
      searchingApiDataset.map((data) =>
        apiDataToIconListLayoutItemObj(
          data,
          preselectedMemberIds,
          preselectedDescription
        )
      ),
    [preselectedDescription, preselectedMemberIds, searchingApiDataset]
  );

  return useMemo(
    () => ({
      initialDataset,
      searchingDataset,
      initialApiLoading,
      searchingApiLoading,
    }),
    [initialApiLoading, initialDataset, searchingApiLoading, searchingDataset]
  );
};
