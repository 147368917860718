import { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import { VirtualRouter } from '@app/web/src/types/router';
import { apis } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { useClubSlug } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import { getShareSettingsByPage } from '../utils';

type SendToDirectOptions = {
  userIds?: string[];
  emails?: string[];
};

export default function useShareToDirect(virtualRouter?: VirtualRouter) {
  const nextRouter = useRouter();
  const router = virtualRouter || nextRouter;
  const { userShareSid } = useContext(SharedUserContext);
  const pageSettings = getShareSettingsByPage(router);
  const clubSlug = useClubSlug();

  const shareToDirect = useCallback(
    ({ userIds = [], emails = [] }: SendToDirectOptions) => {
      if (!userShareSid || !pageSettings) return Promise.reject();

      const params = {
        sharedPathname: router.pathname,
        sharedPathQuery: JSON.stringify(router.query),
        link: router.asPath,
        clubSlug,
        sid: userShareSid,
        receiverUserIds: userIds,
        emails,
        ...pageSettings.params,
      };

      return call(apis.shared.sharePage(params));
    },
    [
      clubSlug,
      pageSettings,
      router.asPath,
      router.pathname,
      router.query,
      userShareSid,
    ]
  );

  return {
    shareToDirect,
    pageSettings,
    userShareSid,
  };
}
