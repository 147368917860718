import { useState } from 'react';
import { alpha, Box, MenuItem, Theme, useMediaQuery } from '@mui/material';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import { getPropertyIcon, Select, TextField } from '@front/ui';

import Icon from '../../components/Icon';
import {
  CustomDirectionDefaultOption,
  CustomDirectionOption,
  SortCriteriaChangedEvent,
  SortCriteriaConfig,
} from '../types';

const styles = {
  root: {
    px: '12px',
    pt: 1,
    '& .textfield-suffix': { pr: '10px' },
    '& .MuiAutocomplete-endAdornment': { right: '10px' },
  },
  labelField: {
    '& .textfield-main': {
      border: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  paper: {
    ml: '-28px !important',
  },
  arrowIcon: {
    pr: '10px',
    pl: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
};

type StandardDirectionOption = Omit<CustomDirectionDefaultOption, 'value'> & {
  value: 'asc' | 'desc';
};

type Option = StandardDirectionOption | CustomDirectionOption;

const SORT_DIRECTIONS: StandardDirectionOption[] = [
  {
    label: 'Ascending',
    value: 'asc',
    icon: 'ActionArrowUp',
    direction: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
    icon: 'ActionArrowDown',
    direction: 'desc',
  },
];

type SortCriteriaProps = SortCriteriaConfig & {
  onChange?: (event: SortCriteriaChangedEvent) => void;
};

export default function SortCriteria({
  id,
  field,
  direction,
  onChange,
}: SortCriteriaProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const directionOptions = field.customDirectionOptions || SORT_DIRECTIONS;
  const initDirection = direction
    ? directionOptions.find((item) => item.value === direction)
    : undefined;

  const [selectedDirection, setSelectedDirection] = useState<Option>(
    initDirection || directionOptions[0]
  );

  function getDirection(value: string) {
    return (
      directionOptions.find((item) => item.value === value) ||
      directionOptions[0]
    );
  }

  const handleChange = (value: string) => {
    const dir = getDirection(value);
    setSelectedDirection(dir);
    onChange?.({
      id,
      field,
      direction: dir.value,
    });
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.labelField}>
        <TextField
          labelIcon={null}
          value={field.label}
          fullWidth
          size="rwd"
          prefix={
            field.customIcon ? (
              <Icon name={field.customIcon} width={16} height={16} />
            ) : (
              getPropertyIcon(field.type, 16)
            )
          }
          suffix={
            <ActionChevronDownIcon
              width={mdUp ? 16 : 24}
              height={mdUp ? 16 : 24}
            />
          }
          disabled
        />
      </Box>
      <Select
        placeholder="Select direction"
        labelIcon={null}
        value={selectedDirection.value}
        onChange={(ev) => handleChange(ev.target.value)}
        size="rwd"
        endAdornment={
          <Box sx={styles.arrowIcon} component="span">
            <ActionChevronDownIcon width={16} height={16} />
          </Box>
        }
        renderDisplay={(option) => getDirection(option).label}
        prefix={
          selectedDirection?.icon ? (
            <Icon name={selectedDirection.icon} width={16} height={16} />
          ) : undefined
        }
      >
        {directionOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <Box sx={styles.optionItem}>
              {option.icon && (
                <Icon name={option.icon} width={16} height={16} />
              )}
              {option.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
