import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  GlobalPanelKeys,
  GlobalPanelParams,
  ThreadPanelKeys,
  ThreadPanelParams,
} from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { IaHoverEvent } from '@lib/ia/src/core/types';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { useCopyToClipboard } from '@lib/web/hooks';
import { QuizAudioProvider } from '@lib/web/practice';
import { useChannel } from '@lib/web/thread/hooks/channel/useChannel';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useSearchMessages } from '@lib/web/thread/hooks/messages/useSearchMessages';
import ThreadChat from '@lib/web/thread/ThreadChat';
import ThreadChatSkeleton from '@lib/web/thread/ThreadChat/components/ThreadChatSkeleton';
import { StreamChatGenerics, ThreadUser } from '@lib/web/thread/types';
import { call } from '@lib/web/utils';
import { Channel, DefaultGenerics, MessageResponse } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import useFloatingProfile from '../../hooks/utils/useFloatingProfile';
import useThreadSortFilter from '../../hooks/utils/useThreadSortFilter';
import useThreadRender from '../../widgets/ThreadPage/hooks/useThreadRender';
import SolutionBlocker from '../SolutionBlocker';

export type WebThreadChatProps = {
  channelCid: string;
  scope?: string;
  position: 'center' | 'rhs';
};

export default function WebThreadChat({
  channelCid,
  scope,
  position,
}: WebThreadChatProps) {
  const router = useRouter();
  const { chatClient, resetThreadMessageSortFilter, openMentionMenuMap } =
    useThread();
  const { openRightPanel: openGlobalRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();
  const {
    openRightPanel: openThreadRightPanel,
    setRightParams: setThreadRightParams,
  } = useBaseRightPanel<ThreadPanelParams>();
  const { blockerTip, member } = useAuth();
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();

  const [, copy] = useCopyToClipboard();
  const { showUserIdProfile } = useFloatingProfile();

  const myMemberId = member?.memberId || '';

  const getMessages = useCallback(
    async (
      messageId: string
    ): Promise<{ message: MessageResponse<DefaultGenerics> }[]> => {
      if (!chatClient) {
        return [];
      }
      const { results } = await chatClient.search(
        { cid: { $eq: channelCid } },
        { id: messageId }
      );
      return results;
    },
    [channelCid, chatClient]
  );

  const fetchReplyMessageText = useCallback(
    async (
      replyMessageId?: string,
      replyMessage?: StreamMessage<StreamChatGenerics>
    ): Promise<string> => {
      if (replyMessage?.text) {
        return replyMessage.text;
      }
      if (replyMessageId) {
        const messages = await getMessages(replyMessageId);
        if (messages.length > 0 && messages[0].message.text)
          return messages[0].message.text;
      }
      return '';
    },
    [getMessages]
  );

  const availableActions = {
    viewUser: {
      action: ({ userId }: { userId: string }) => {
        openGlobalRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: userId,
        });
      },
    },
    viewAgent: {
      action: ({ userId }: { userId: string }) => {
        openGlobalRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId: userId,
        });
      },
    },
    showFloatingProfile: {
      action: (event: IaHoverEvent<{ userId: string }>) => {
        showUserIdProfile({
          userId: event.target.userId,
          anchorEl: event.anchorEl,
        });
      },
    },
    viewThread: {
      action: (
        _id: string,
        metadata: { childChannelCid: string | undefined }
      ) => {
        if (!metadata.childChannelCid) return;

        openGlobalRightPanel(GlobalPanelKeys.GlobalThreadChat, {
          channelCid: metadata.childChannelCid,
        });
      },
    },
    openThread: {
      action: (
        _id: string,
        metadata: {
          channel: Channel;
          message: StreamMessage;
          childChannelCid: string | undefined;
        }
      ) => {
        if (metadata.childChannelCid) {
          openGlobalRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: metadata.childChannelCid,
          });
          return;
        }

        const tmpId = v4();
        openGlobalRightPanel(
          GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel,
          {
            tmpId,
            channel: metadata.channel,
            message: metadata.message,
          }
        );
      },
    },
    openUserOrAgentProfile: {
      action: ({ user }: { user?: ThreadUser }): void => {
        if (user?.type === 'agent') {
          openGlobalRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
            agentId: user.agentId ?? '',
          });
        }
        if (user?.type === 'user') {
          openGlobalRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId: user.userId,
          });
        }
      },
    },
    viewSenderProfile: {
      action: (event: IaHoverEvent<{ user?: ThreadUser }>): void => {
        if (!event.target || event.target.user?.type !== 'user') return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.user.userId || '',
        });
      },
    },
    editModeMessageContentOnKeyDown: {
      action: (
        {
          handler,
        }: {
          handler: (
            e: KeyboardEvent,
            text: string,
            changedBlocks: ComposerBlock[]
          ) => void;
        },
        event: KeyboardEvent,
        text: string,
        changedBlocks: ComposerBlock[]
      ): void => {
        handler(event, text, changedBlocks);
      },
    },
    editModeMessageContentOnBlur: {
      action: ({ handler }: { handler: () => void }): void => {
        handler();
      },
    },
    copyMessage: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
          handler: () => void;
        }
      ) => {
        const { plainText, handler } = metadata;
        void copy(plainText);
        handler();
      },
    },
    tryAgain: {
      action: (
        _id: string,
        metadata?: {
          channelType: 'public' | 'team';
          channelId?: string;
          message: StreamMessage<StreamChatGenerics>;
          handler?: () => void;
        }
      ): void => {
        if (!metadata) {
          return;
        }

        const { channelType, channelId, message, handler } = metadata;
        handler?.();
        void call(
          apis.func.threadRecompletion({
            channelType,
            channelId: channelId || '',
            messageId: message.id,
            requestUserId: myMemberId,
            agentUserId: message.user?.id || '',
          })
        );
      },
    },
    prevMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('prev');
      },
    },
    nextMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('next');
      },
    },
    duplicateThreadToPublic: {
      action: async (
        _id: string,
        metadata: {
          replyMessageId?: string;
          replyMessage?: StreamMessage<StreamChatGenerics>;
        }
      ): Promise<void> => {
        const { replyMessageId, replyMessage } = metadata;

        const messageText = await fetchReplyMessageText(
          replyMessageId,
          replyMessage
        );
        if (messageText) {
          const newChannelResult = await createNewChannelWithMessage({
            message: messageText,
            blocks: [],
            sendPublicly: true,
            channelData: { members: [myMemberId] },
          });
          if (newChannelResult?.newChannel?.cid) {
            await router.push('/direct-messages/view/everyone');
            openGlobalRightPanel(GlobalPanelKeys.GlobalThreadChat, {
              channelCid: newChannelResult.newChannel.cid,
            });
          }
        }
      },
    },
    mentionFriends: {
      action: (): void => {
        const openMentionMenu = openMentionMenuMap.current.get(channelCid);
        openMentionMenu?.();
      },
    },
    askFriends: {
      action: async (
        _id: string,
        metadata: {
          replyMessageId?: string;
          replyMessage?: StreamMessage<StreamChatGenerics>;
        }
      ) => {
        const { replyMessageId, replyMessage } = metadata;
        const messageText = await fetchReplyMessageText(
          replyMessageId,
          replyMessage
        );
        if (messageText) {
          openThreadRightPanel(ThreadPanelKeys.DmThreadNewThread);
          setThreadRightParams({ defaultMessageText: messageText });
        }
      },
    },
    editMessage: {
      action: (
        _id: string,
        metadata: {
          handler: () => void;
        }
      ): void => {
        const { handler } = metadata;
        handler();
      },
    },
    deleteMessage: {
      action: (
        _id: string,
        metadata: {
          handler: () => void;
        }
      ): void => {
        const { handler } = metadata;
        handler();
      },
    },
  };

  const renders = useThreadRender();

  const { useMessageSortFilter } = useThreadSortFilter(scope);
  const { sort, resetSort, filters, resetFilter } = useMessageSortFilter();

  const { channel } = useChannel({ channelCid });

  const { messages } = useSearchMessages({
    channelCid,
    messageSort: sort,
    messageFilter: filters,
  });

  const availableEventNames = [
    'openThread',
    'copyMessage',
    'tryAgain',
    'prevMessagePage',
    'nextMessagePage',
    'callOut',
    'editMessage',
    'deleteMessage',
  ];

  useEffect(() => {
    resetThreadMessageSortFilter.current = (): void => {
      resetSort();
      resetFilter();
    };
  }, [resetFilter, resetSort, resetThreadMessageSortFilter]);

  const renderThreadChat = (
    <ThreadChat
      channelCid={channelCid}
      channel={channel}
      tooltip={blockerTip}
      searchedMessages={messages}
      availableEventNames={availableEventNames}
    />
  );

  return (
    <QuizAudioProvider>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <EditorThreadComposerRenderer styleProvider>
            {!channel ? (
              <ThreadChatSkeleton />
            ) : channel.data?.tag === 'solution' && channel.data.questionId ? (
              <SolutionBlocker
                quizQuestionId={channel.data.questionId}
                placement={position}
              >
                {renderThreadChat}
              </SolutionBlocker>
            ) : (
              renderThreadChat
            )}
          </EditorThreadComposerRenderer>
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </QuizAudioProvider>
  );
}
