import { alpha, ButtonBase, Theme, Typography } from '@mui/material';
import { SquareAvatar } from '@front/ui';
import { getClubIconPath } from '@lib/web/utils';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: '6px',
    px: '2px',
    pb: '2px',
    borderRadius: 1,
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  name: {
    textDecoration: 'underline',
    textDecorationColor: (theme: Theme) =>
      alpha(theme.palette.text.primary, 0.3),
    textUnderlineOffset: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (hover:hover)': {
      '&:hover': {
        textDecorationColor: (theme: Theme) => theme.palette.text.primary,
      },
    },
  },
};

type ClubHomeProps = {
  cta: Extract<NotificationCtaType, { type: 'cta.club.home' }>;
};

export default function ClubHome({ cta }: ClubHomeProps) {
  const clubIcon = cta.clubIcon || getClubIconPath(cta.clubName);
  return (
    <ButtonBase sx={styles.root} href={`/club/${cta.clubSlug}`}>
      <SquareAvatar src={clubIcon} size={16}>
        {cta.clubName}
      </SquareAvatar>
      <Typography variant="body2" sx={styles.name}>
        {cta.clubName}
      </Typography>
    </ButtonBase>
  );
}
