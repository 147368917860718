import { KeyboardEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { FILTER_OPERATOR_MAP } from '../../defaultConfig';
import { FilterField, FilterOperator } from '../../types';

type NumberFilterProps = {
  item: FilterField;
};
export default function NumberFilter({ item }: NumberFilterProps) {
  const { control, watch } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: item.name,
    rules: { required: true },
  });
  const { getIaAction } = useIaAction();
  const onEnter = getIaAction('onEnter');

  const handleInputKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      onEnter?.action();
    }
  };

  const operatorValue = watch('operator');
  const operandCount =
    FILTER_OPERATOR_MAP[operatorValue as FilterOperator]?.operandCount;
  const valueCount = typeof operandCount === 'undefined' ? 1 : operandCount - 1;

  if (valueCount > 1) {
    const multipleValues = Array.isArray(value) ? value : [value];

    const handleMultipleChange = (newValue: string, index: number) => {
      const newValues = [...multipleValues];
      newValues[index] = newValue;

      onChange(newValues);
    };

    return [...new Array(valueCount)].map((_, index) => (
      <TextField
        key={index}
        labelIcon={null}
        fullWidth
        size="rwd"
        placeholder={item.placeholder || 'Type a value...'}
        type="number"
        onChange={(ev) => handleMultipleChange(ev.target.value, index)}
        value={multipleValues[index] ?? ''}
        autoFocus
      />
    ));
  }

  const singleValue = Array.isArray(value) ? value[0] : value;

  return (
    <TextField
      labelIcon={null}
      fullWidth
      size="rwd"
      placeholder={item.placeholder || 'Type a value...'}
      type="number"
      onChange={onChange}
      value={singleValue ?? ''}
      autoFocus
      onKeyDown={handleInputKeyDown}
    />
  );
}
