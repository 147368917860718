import { useMemo } from 'react';
import { AgentPermission, useAuth } from '@lib/web/apis';

export const useIaAgentAccessControl = () => {
  const { userId } = useAuth();
  return useMemo(
    () => ({
      isEditable: ({ agentCreator }: { agentCreator: string }) => {
        return userId === agentCreator;
      },
      isDuplicatable: ({
        permission,
        agentCreator,
      }: {
        permission: AgentPermission;
        agentCreator: string;
      }) => {
        return (
          userId === agentCreator || permission === AgentPermission.CanEdit
        );
      },
    }),
    [userId]
  );
};
