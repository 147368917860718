import { Ref } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import { ResponsiveToolbar } from '@lib/web/ui';

import useIaFilterValues from '../../hooks/utils/useIaFilterValues';
import useIaSortValues from '../../hooks/utils/useIaSortValues';
import { useToolbarMoreButtonDot } from '../../hooks/utils/useToolbarMoreButtonDot';

export default function ThreadViewPanelToolbar({
  sortButtonRef,
  filterButtonRef,
  scope,
  filterOrSortOpened,
}: {
  sortButtonRef: Ref<HTMLButtonElement>;
  filterButtonRef: Ref<HTMLButtonElement>;
  scope: string;
  filterOrSortOpened?: 'filter' | 'sort' | null;
}) {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { setRightParams, rightPanelParams } = useBaseRightPanel();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const commonToolbarItems = useCommonToolbarItems({ enableThread: false });
  const { moreButtonDot } = useToolbarMoreButtonDot(scope, 'rhs');
  const { hasFilter } = useIaFilterValues({ scope });
  const { hasSort } = useIaSortValues({ scope });

  return (
    <ResponsiveToolbar
      items={[
        {
          type: 'Sort',
          onClick: () => {
            setRightParams({
              ...rightPanelParams,
              filterOrSortOpened: 'sort',
            });
          },
          onHover: () => {
            setRightParams({
              ...rightPanelParams,
              filterOrSortOpened: 'sort',
            });
          },
          selected: filterOrSortOpened === 'sort',
          highlight: hasSort,
          hiddenInMore: true,
          disabledAutoClose: mdUp,
          buttonRef: sortButtonRef,
          actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
          className: 'right-panel-toolbar-item',
        },
        {
          type: 'Filter',
          onClick: () => {
            setRightParams({
              ...rightPanelParams,
              filterOrSortOpened: 'filter',
            });
          },
          onHover: () => {
            setRightParams({
              ...rightPanelParams,
              filterOrSortOpened: 'filter',
            });
          },
          selected: filterOrSortOpened === 'filter',
          highlight: hasFilter,
          hiddenInMore: true,
          disabledAutoClose: mdUp,
          buttonRef: filterButtonRef,
          actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
          className: 'right-panel-toolbar-item',
        },
        {
          type: 'Layout',
          onClick: () =>
            openRightPanel(GlobalPanelKeys.GlobalLayout, {
              supportableLayouts: {
                channel: true,
                table: true,
              },
            }),
        },
        ...commonToolbarItems,
      ]}
      moreButtonDot={moreButtonDot}
      shouldKeepOpened={filterOrSortOpened !== null && mdUp}
      isOptionEqualToValue={(option) =>
        option.item.type.toLowerCase() === filterOrSortOpened
      }
    />
  );
}
