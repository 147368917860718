import { useCallback } from 'react';
import {
  forceResetFilter as forceResetFilterFn,
  removeCondition as removeConditionFn,
  resetFilter as resetFilterFn,
  setConditions as setConditionsFn,
  setFields as setFieldsFn,
  upsertCondition as upsertConditionFn,
} from '@lib/ia/src/filter/reducers/filterReducer';
import {
  FilterConditionConfig,
  FilterOperator,
  FilterValues,
} from '@lib/ia/src/filter/types';
import { compareCondition } from '@lib/ia/src/filter/utils';
import { v4 } from 'uuid';

import { FilterType } from '../../types/filter';
import useGlobalFilterConfigs from '../../widgets/CommonPanels/FilterPanel/hooks/useGlobalFilterConfigs';
import { useAppDispatch, useAppSelector } from '../redux';

type Options = {
  scope?: string;
};

export default function useIaFilterActions({ scope = 'global' }: Options = {}) {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((st) => st.filter);
  const configs = useGlobalFilterConfigs();

  const removeConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return;
      }
      const condition = filter.conditions[scope].find(
        (item) => item.field.name === fieldName
      );
      if (!condition || !condition.id) return;
      dispatch(removeConditionFn({ id: condition.id, scope }));
    },
    [dispatch, filter.conditions, scope]
  );

  const getConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return;
      }
      return filter.conditions[scope].find(
        (item) => item.field.name === fieldName
      );
    },
    [filter.conditions, scope]
  );

  const hasConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return false;
      }
      return filter.conditions[scope].some(
        (item) => item.field.name === fieldName
      );
    },
    [filter.conditions, scope]
  );

  const replaceCondition = useCallback(
    (
      filterType: FilterType,
      fieldName: string,
      operator: FilterOperator,
      values?: FilterValues,
      options?: {
        disabled?: boolean;
        viewOnly?: boolean;
      }
    ) => {
      const field = configs?.[filterType]?.fields?.find(
        (f) => f.name === fieldName
      );
      if (!field) return;
      removeConditionByField(fieldName);

      const condition: FilterConditionConfig = {
        id: v4(),
        field,
        operator,
        values,
        ...options,
      };
      dispatch(upsertConditionFn({ condition, scope }));
    },
    [configs, dispatch, removeConditionByField, scope]
  );

  const isConditionExist = useCallback(
    (fieldName: string, operator: FilterOperator, values?: any) => {
      const con1 = getConditionByField(fieldName);
      const con2 = {
        field: { name: fieldName },
        operator,
        values,
      } as FilterConditionConfig;
      return compareCondition(con1, con2);
    },
    [getConditionByField]
  );

  const setConditions = useCallback(
    (conditions: FilterConditionConfig[]) =>
      dispatch(setConditionsFn({ conditions, scope })),
    [dispatch, scope]
  );

  const resetFilter = useCallback(
    () => dispatch(resetFilterFn({ scope })),
    [dispatch, scope]
  );

  const forceResetFilter = useCallback(
    () => dispatch(forceResetFilterFn({ scope })),
    [dispatch, scope]
  );

  const upsertCondition = useCallback(
    (condition: FilterConditionConfig) =>
      dispatch(upsertConditionFn({ condition, scope })),
    [dispatch, scope]
  );

  const removeCondition = useCallback(
    (id: string) => dispatch(removeConditionFn({ id, scope })),
    [dispatch, scope]
  );

  const resetToDefault = useCallback(
    (type?: FilterType) => {
      if (!type) return;
      dispatch(resetFilterFn({ scope }));
      const filterConfig = configs[type];
      dispatch(setFieldsFn({ fields: filterConfig?.fields || [], scope }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, scope]
  );

  return {
    removeConditionByField,
    getConditionByField,
    hasConditionByField,
    replaceCondition,
    isConditionExist,
    setConditions,
    resetFilter,
    forceResetFilter,
    upsertCondition,
    removeCondition,
    resetToDefault,
  };
}
