import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'next-i18next';
import { alpha, Box, InputProps, Theme } from '@mui/material';
import HorizontalTextAnimation from '@app/web/src/components/HorizontalTextAnimation';
import { Hoverable } from '@front/helper';
import { OtherEdit as OtherEditIcon } from '@front/icon';
import { IconButton, IndicatorGroup, ResponsiveTooltip } from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { ThreadUser } from '@lib/web/thread/types';
import ThreadEmojiPicker from '@lib/web/thread/ui/ThreadEmojiPicker';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { ResponsivePageTitle } from '@lib/web/ui';

import useThreadViewChangeEmoji from '../../hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '../../hooks/useThreadViewRename';

const styles = {
  title: {
    minWidth: 0,
    position: 'relative',
  },
  iconButtonWrap: {
    position: 'absolute',
    right: '100%',
    top: 0,
    bottom: 0,
    pr: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'background.menu',
    minWidth: 28,
    height: 28,
    borderRadius: 1,
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${
        theme.palette.mode === 'light'
          ? 'rgba(8, 8, 8, 0.05)'
          : 'rgba(255, 255, 255, 0.1)'
      }`,
  },
  name: {
    px: '4px',
    py: '2px',
    height: 42,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  hoverName: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
  },
  h1: (theme: Theme) => ({
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    ...theme.typography.appH1,
    fontFamily: theme.typography.fontFamily,
  }),
  input: {
    width: '100%',
    px: '4px',
    py: '2px',
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
    height: 43,
  },
  indicators: {
    verticalAlign: 'middle',
  },
};

const MAX_VISIBLE_MEMBER_COUNT = 3;

const EDITABLE_THREAD_VIEW_TYPES = [
  ThreadViewType.DmGroup,
  ThreadViewType.Club,
];

const SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

type GetTooltipContentProps = {
  t: TFunction;
  viewType: ThreadViewType;
};

const getTooltipContentProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.rename.description',
        'click to rename, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.rename.description',
        'click to rename, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.rename.description', 'click to rename.');

    default:
      return null;
  }
};

type TitleTextProps = {
  viewNameMembers: NonNullable<ThreadUser | null>[] | null;
  viewType: ThreadViewType;
  viewName: string;
  handleChangeViewName: (ev: ChangeEvent<HTMLInputElement>) => void;
};

function NameInput({
  value,
  onChange,
  onBlur,
}: {
  value: string;
  onChange: InputProps['onChange'];
  onBlur: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    });
  }, []);
  return (
    <Box
      ref={inputRef}
      sx={[styles.h1, styles.input]}
      component="input"
      value={value || ''}
      placeholder="Untitled"
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

const TitleText = ({
  viewNameMembers,
  viewType,
  viewName,
  handleChangeViewName,
}: TitleTextProps) => {
  const { t } = useTranslation('thread');
  const [editable, setEditable] = useState(false);
  const [firstItem] = viewNameMembers || [];
  // Format the viewName to display member names or "+x others" when member count exceeds 3
  const formattedViewName = useMemo(() => {
    if (
      viewNameMembers?.length &&
      viewNameMembers.length > MAX_VISIBLE_MEMBER_COUNT
    ) {
      const displayedMembers = viewNameMembers
        .slice(0, MAX_VISIBLE_MEMBER_COUNT)
        .map((m) => m.name);
      const remainingCount = viewNameMembers.length - MAX_VISIBLE_MEMBER_COUNT;
      return `${displayedMembers.join(', ')} +${remainingCount} others`;
    }
    return viewName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(viewNameMembers), viewName]);

  if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
    if (editable) {
      return (
        <NameInput
          value={formattedViewName}
          onChange={handleChangeViewName}
          onBlur={() => setEditable(false)}
        />
      );
    }
    return (
      <Hoverable sx={styles.title}>
        {({ hovered, hoveredListeners, resetHovered }) => {
          const ViewTitleWrap = hovered ? HorizontalTextAnimation : Box;

          return (
            <Box
              {...hoveredListeners}
              onClick={() => {
                setEditable(true);
                resetHovered();
              }}
            >
              {hovered && (
                <Box sx={styles.iconButtonWrap}>
                  <ResponsiveTooltip
                    content="Rename thread title"
                    tooltipProps={{ followCursor: true }}
                  >
                    <Box sx={styles.iconButton}>
                      <IconButton customSize={24}>
                        <OtherEditIcon />
                      </IconButton>
                    </Box>
                  </ResponsiveTooltip>
                </Box>
              )}
              <ResponsiveTooltip
                title={viewName}
                content={getTooltipContentProps({ t, viewType })}
                tooltipProps={{ followCursor: true }}
              >
                <Box>
                  <ViewTitleWrap
                    sx={[styles.name, hovered && styles.hoverName]}
                  >
                    {formattedViewName}
                  </ViewTitleWrap>
                </Box>
              </ResponsiveTooltip>
            </Box>
          );
        }}
      </Hoverable>
    );
  }

  return (
    <>
      {viewName}{' '}
      {SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES.includes(viewType) &&
        firstItem?.indicators && (
          <IndicatorGroup
            sx={styles.indicators}
            indicators={firstItem.indicators}
          />
        )}
    </>
  );
};

type ThreadTitleProps = {
  view: GetThreadViewRes;
  clubId?: string;
  subtitleText?: ReactNode;
};

const ThreadViewTitle = ({ view, clubId, subtitleText }: ThreadTitleProps) => {
  const { viewIcon, viewNameMembers, viewType } = useThreadViewDetail(view);

  const { emoji, handleOpenEmojiPanel } = useThreadViewChangeEmoji({
    view,
    clubId,
  });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
    clubId,
  });

  return (
    <ResponsivePageTitle
      icon={
        viewIcon?.type === 'emoji' ? (
          <ThreadEmojiPicker
            emoji={emoji}
            onEmojiClick={handleOpenEmojiPanel}
          />
        ) : (
          <ThreadViewIcon view={view} size="md" />
        )
      }
      titleText={
        <TitleText
          viewNameMembers={viewNameMembers}
          viewType={viewType as ThreadViewType}
          viewName={viewName}
          handleChangeViewName={handleChangeViewName}
        />
      }
      subtitleText={subtitleText}
    />
  );
};

export default ThreadViewTitle;
