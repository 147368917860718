import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IconButton, SimpleTooltip } from '@front/ui';

export type ThreadEmojiPickerProps = {
  emoji?: GetMarkEmojiRes;
  onEmojiClick?: () => void;
};

export default function ThreadEmojiPicker({
  emoji,
  onEmojiClick,
}: ThreadEmojiPickerProps) {
  const { t } = useTranslation('thread');

  return (
    <SimpleTooltip
      title={
        <>
          <Typography>{emoji?.name}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.64 }}>
            {t('club.emoji.tooltip.title')}
          </Typography>
        </>
      }
    >
      <IconButton customSize={30} onClick={onEmojiClick}>
        {emoji?.code}
      </IconButton>
    </SimpleTooltip>
  );
}
