import { useRef } from 'react';
import Router from 'next/router';
import { CreateQuizHandler } from '@app/web/src/components/CreateQuiz/CreateQuiz';
import CreateQuizByPanel from '@app/web/src/components/CreateQuiz/CreateQuizByPanel';
import {
  CreateQuizFormPartTypes,
  CreateQuizFormValue,
} from '@app/web/src/components/CreateQuiz/type';
import { useIaPlaylistQuestionDataForDynamicView } from '@app/web/src/ia/playlistQuestion';
import { BaseLayoutRightPanelPortal } from '@front/ui';
import {
  apis,
  ExamMode,
  QuizGroupType,
  QuizQuestionStatus,
  QuizType,
  RedoQuestionType,
  useQuizHashtags,
} from '@lib/web/apis';
import { useCurrentExam, useCurrentIaClub } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';
import { format } from 'date-fns';

import usePlaylistInfo from '../../hooks/usePlaylistInfo';

type CreateQuizPanelProps = {
  data: GetQuizResultSummaryRes;
  mistakeOnly?: boolean;
  redo?: boolean;
  panelKeyPrefix: string;
  formId?: string;
  selectedTag?: string;
  clubSlug?: string;
  defaultDisabledParts: CreateQuizFormPartTypes[];
};

export default function CreateQuizPanel({
  data,
  mistakeOnly,
  redo,
  panelKeyPrefix,
  formId,
  selectedTag,
  clubSlug,
  defaultDisabledParts = [],
}: CreateQuizPanelProps) {
  const quizFormRef = useRef<CreateQuizHandler>(null);
  const { sectionId } = useCurrentExam(clubSlug);
  const { club } = useCurrentIaClub(clubSlug);
  const { quizId, markId, dynamicType, isCommon } = usePlaylistInfo();
  const { data: hashtagsData } = useQuizHashtags({
    quizId,
    dynamicType,
    sectionId,
    sourceId: markId,
  });

  const {
    questions: dynamicPlaylistQuestions,
    totalCount: dynamicPlaylistQuestionCount,
  } = useIaPlaylistQuestionDataForDynamicView({ ignoreFilters: true });

  if (!club) return null;

  const tags = hashtagsData?.data
    .filter((tag) => (mistakeOnly ? tag.isMistake : true))
    .map((tag) => tag.code);
  const tagIds = hashtagsData?.data
    .filter((tag) => (mistakeOnly ? tag.isMistake : true))
    .map((tag) => tag.id);

  const { mode: quizMode, questionCount } = data.quiz;
  const mode = mistakeOnly ? ExamMode.Practice : quizMode;
  const isTaken = data.challenge
    ? data.challenge.isTaken
    : Boolean(data.quiz.latestRoundNo && data.quiz.lastTakenAt);

  const selectedTagQuestionCount = data.quizQuestions?.length || 0;

  const isMistake = (item: GetQuizQuestionSummaryRes) =>
    item.quizResult?.isAnswered && !item.quizResult?.isCorrect;

  const isRemoved = (item: { status?: QuizQuestionStatus }) =>
    !!item.status &&
    ![QuizQuestionStatus.Enabled, QuizQuestionStatus.Internal].includes(
      item.status
    );

  const mistakeNotDeletedCount = data.quizQuestions
    ?.filter(isMistake)
    .filter((item) => !isRemoved(item.quizQuestion)).length;

  const questionCountValue = mistakeOnly
    ? mistakeNotDeletedCount
    : dynamicType
    ? Math.min(club.officialQuestionCount, dynamicPlaylistQuestionCount)
    : selectedTag
    ? Math.min(club.officialQuestionCount, selectedTagQuestionCount)
    : Math.min(club.officialQuestionCount, questionCount);

  const handleBeforeGetQuiz = async (params: CreateQuizFormValue) => {
    const isOpenTimer =
      params.mode === ExamMode.MockExam ? params.mockTimer : params.turboTimer;

    // mistake only for normal playlist
    if (mistakeOnly && !dynamicType) {
      // mistake but not deleted
      const mistakeQuestionIds = data.quizQuestions
        .filter(isMistake)
        .filter((item) => !isRemoved(item.quizQuestion))
        .map((item) => item.quizQuestion.examQuestionId);

      const [res] = await callWithToast(
        apis.quiz.quizByExamQuestionId({
          sectionId: sectionId || '',
          examQuestionIdAry: mistakeQuestionIds,
          questionCount: mistakeQuestionIds.length,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false;

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: res.data.roundNo,
      };
    }

    // mistake only with dynamicType
    if (mistakeOnly && !!dynamicType) {
      // mistake but not deleted
      const mistakeQuestionIds = dynamicPlaylistQuestions
        .filter((item) => item.isAnswered && !item.isCorrect)
        .filter((item) => !isRemoved(item))
        .filter((item) => !!item.metadata.examQuestionId)
        .map((item) => item.metadata.examQuestionId as string);

      const [res] = await callWithToast(
        apis.quiz.quizByExamQuestionId({
          sectionId: sectionId || '',
          examQuestionIdAry: mistakeQuestionIds,
          questionCount: mistakeQuestionIds.length,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false;

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: res.data.roundNo,
      };
    }

    // redo with dynamicType
    if (redo && !!dynamicType) {
      const questionIds = dynamicPlaylistQuestions
        .filter((item) => !!item.metadata.examQuestionId)
        .map((item) => item.metadata.examQuestionId as string);

      const [res] = await callWithToast(
        apis.quiz.quizByExamQuestionId({
          sectionId: sectionId || '',
          examQuestionIdAry: questionIds,
          questionCount,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false;

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: res.data.roundNo,
      };
    }

    // redo normal playlist (without selected tag)
    if (redo && !dynamicType && !selectedTag) {
      const [res] = await callWithToast(
        apis.quiz.redoQuizPractice({
          quizId: data.quiz.id,
          questionType: RedoQuestionType.All,
          filterHashtagIdAry: tagIds,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false;

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: res.data.roundNo,
      };
    }

    // redo normal playlist (with a selected tag)
    if (redo && !dynamicType && !!selectedTag) {
      const questionIds = data.quizQuestions.map(
        (item) => item.quizQuestion.examQuestionId
      );

      const [res] = await callWithToast(
        apis.quiz.quizByExamQuestionId({
          sectionId: sectionId || '',
          examQuestionIdAry: questionIds,
          questionCount,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false;

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: res.data.roundNo,
      };
    }

    // common mistake/overtime
    if (
      dynamicType &&
      [QuizGroupType.Mistake, QuizGroupType.Overtime].includes(dynamicType) &&
      markId &&
      sectionId &&
      isCommon
    ) {
      const [res] = await callWithToast(
        apis.quiz.quizByMark({
          sectionId,
          markId,
          questionCount: params.questionCount,
          isOpenTimer: isOpenTimer,
          isCommon,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false; // error response

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: 1,
      };
    }

    // Reaction
    if (dynamicType === QuizGroupType.Emoji && markId && sectionId) {
      const [res] = await callWithToast(
        apis.quiz.quizByMark({
          sectionId,
          markId,
          questionCount: params.questionCount,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false; // error response

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: 1,
      };
    }
    // Hashtag
    if (dynamicType === QuizGroupType.Hashtags && markId && sectionId) {
      const [res] = await callWithToast(
        apis.quiz.quizByHashtag({
          sectionId,
          tagId: markId,
          questionCount: params.questionCount,
          isOpenTimer: isOpenTimer,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false; // error response

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: 1,
      };
    }
    // Challenge
    if (params.randomChallengerCount || params.challengers.length) {
      const [res] = await callWithToast(
        apis.challenge.challengeByQuiz({
          quizId: data.quiz.id,
          challengerIds: params.challengers,
          isOpenTimer: isOpenTimer,
          maxAttemptNum: params.maxAttempt,
          expireAt: format(params.deadline, 'yyyy-MM-dd'),
          randomChallengerCount: params.randomChallengerCount,
        }),
        {
          errorMsg: 'Something went wrong, please try again!',
        }
      );
      if (!res) return false; // error response

      if (data.quiz.id) {
        // turn a normal playlist into a challenge playlist
        const quizIdPath = res.data.quizShortId || res.data.quizId;
        Router.push(`/club/${club.clubSlug}/challenge/${quizIdPath}`);
        return false; // stop logic behind
      }

      return {
        mode: params.mode,
        quizId: res.data.quizShortId || res.data.quizId,
        roundNo: 1,
      };
    }

    // Existing Quiz => start new round
    if (!data.challenge && (data.quiz.shortId || data.quiz.id)) {
      return {
        mode: params.mode,
        quizId: data.quiz.shortId || data.quiz.id,
        roundNo: Math.max(
          isTaken ? data.quiz.latestRoundNo + 1 : data.quiz.latestRoundNo,
          1
        ),
      };
    }

    // no return to use default flow
  };

  const challengeDisabled =
    data.quiz.type === QuizType.CreatorUserQuiz || !!dynamicType;
  const disabledParts = defaultDisabledParts.concat(
    challengeDisabled ? ['challenge'] : []
  );

  return (
    <BaseLayoutRightPanelPortal>
      <CreateQuizByPanel
        panelKeyPrefix={panelKeyPrefix}
        disabledParts={disabledParts}
        defaultValues={{
          mode: mode ?? ExamMode.Practice,
          questionCount: questionCountValue,
          tags: selectedTag ? [selectedTag] : tags,
        }}
        ref={quizFormRef}
        onBeforeGetQuiz={handleBeforeGetQuiz}
        formId={formId}
        clubSlug={clubSlug}
      />
    </BaseLayoutRightPanelPortal>
  );
}
