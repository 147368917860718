import { useMemo, useState } from 'react';
import Router from 'next/router';
import usePlaylistFilter from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistFilter';
import usePlaylistInfo from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistInfo';
import {
  ChallengeStatus,
  ExamMode,
  ExamViewType,
  InfiniteHookResponse,
  OvertimeStatus,
  PlaylistViewSlug,
  PracticeResult,
  QuizGroupType,
  useAuth,
  useIaPlaylists,
  useQuizDynamicQuestionList,
  useQuizQuestionMarkedEmojiMultiple,
  useQuizResultSummary,
} from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import { useSearchFilterSort } from '../../hooks/utils/useSearchFilterSort';
import usePlaylistSummary from '../../widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';

import { IaPlaylistQuestionDataItem } from './types';

// XXX: separate data into different purpose to make it clear
export type IaPlaylistQuestionData = {
  questions: IaPlaylistQuestionDataItem[];
  playlistPath: string;
  sectionViewType: ExamViewType;
  data: GetQuizResultSummaryRes | undefined;
  reloadData: (params: {
    mutatorPromise?: Promise<any>;
    optimisticQuestions?: IaPlaylistQuestionDataItem[];
  }) => void;
  hideQuestion: boolean;
  state: 'notReachedEnd' | 'reachedEnd' | 'empty' | 'loading';
  setQuestionsSize?: ReturnType<typeof useQuizDynamicQuestionList>['setSize'];
  questionsPageCount?: number;
  totalCount: number;
  isTaken: boolean;
};

const getPlaylistPath = (data?: GetQuizResultSummaryRes) => {
  if (!data) {
    return '';
  }

  const { latestRoundNo, mode, id, shortId } = data.quiz;

  if (!id) {
    return '';
  }

  return `/club/${Router.query.slug}/${
    mode === ExamMode.MockExam ? 'exam' : 'practice'
  }/${shortId || id}/${latestRoundNo || 1}`;
};

const useFrontendFilteredQuestions = (
  questions: IaPlaylistQuestionDataItem[]
): IaPlaylistQuestionDataItem[] => {
  const { isFilterMistake, isFilterOvertime, isFilterEmoji } =
    usePlaylistFilter();

  return useMemo(() => {
    return questions.filter((question) => {
      if (isFilterMistake && question.isCorrect) {
        return false;
      }
      if (isFilterOvertime && !question.isOvertime) {
        return false;
      }
      if (isFilterEmoji && !question.emoji) {
        return false;
      }

      return true;
    });
  }, [isFilterEmoji, isFilterMistake, isFilterOvertime, questions]);
};

export const useIaPlaylistQuestionDataForNonLoginUser = ({
  quizId,
}: {
  quizId: string;
}): IaPlaylistQuestionData => {
  const {
    data,
    mutate: reloadSummary,
    isLoading,
  } = useQuizResultSummary({ quizId });

  const {
    dataset: playlistQuestions,
    isLoading: questionsLoading,
    mutate: reloadQuestions,
  } = useIaPlaylists(
    {
      search: `quizId:${quizId};`,
      searchFields: `quizId:eq;`,
      filter: 'questionContent;isAnswered;sourceType',
      viewSlug: PlaylistViewSlug.AlbumCenterPlaylistDefault,
    },
    {
      enable: !!quizId,
    }
  );

  const isChallenge = !!data?.data.challenge;
  const isIncompleteMock =
    data?.data.quiz.mode === ExamMode.MockExam && !data?.data.quiz.isCompleted;
  const questionData = data?.data;
  const playlistPath = getPlaylistPath(questionData);
  const sectionViewType = questionData?.quiz?.sectionViewType;

  const [optimisticUpdateQuestions, setOptimisticUpdateQuestions] = useState<
    IaPlaylistQuestionDataItem[] | null
  >();

  const questions = useMemo(() => {
    if (optimisticUpdateQuestions) return optimisticUpdateQuestions;

    if (!playlistQuestions || !data) {
      return [];
    }

    return playlistQuestions.map((question) => ({
      isSkipped: question === null || question.isAnswered === null,
      isAnswered: question.isAnswered,
      isCorrect: question.practiceResult === PracticeResult.Correct,
      isOvertime: question.overtimeStatus === OvertimeStatus.Overtime,
      questionContent: question.questionContent,
      questionNumber: question.questionNumber,
      id: question.quizQuestionId,
      sourceType: question.sourceType,
      emoji: question.emoji,
      metadata: {
        questionId: question.quizQuestionId,
      },
      difficulty: {
        isDynamicDifficulty: question.isDynamicDifficulty,
        dynamicDifficulty: question.dynamicDifficulty,
        staticDifficulty: question.staticDifficulty,
      },
      correctRate: question.correctRate,
      overtimeRate: question.overtimeRate,
    }));
  }, [data, optimisticUpdateQuestions, playlistQuestions]);

  const filteredQuestions = useFrontendFilteredQuestions(questions);

  const reloadData: IaPlaylistQuestionData['reloadData'] = async ({
    optimisticQuestions,
    mutatorPromise,
  }) => {
    setOptimisticUpdateQuestions(optimisticQuestions);
    await mutatorPromise;
    await reloadSummary();
    await reloadQuestions();
    setOptimisticUpdateQuestions(null);
  };

  return {
    sectionViewType,
    data: questionData,
    reloadData,
    state:
      !data || isLoading || questionsLoading
        ? 'loading'
        : questions.length === 0
        ? 'empty'
        : 'reachedEnd',
    questions: filteredQuestions,
    totalCount: questions.length,
    playlistPath,
    hideQuestion: isChallenge || isIncompleteMock,
    isTaken: !!data?.data.quiz.lastTakenAt,
  };
};

export const useIaPlaylistQuestionData = ({
  quizId,
  tagId,
  questionData,
}: {
  quizId: string;
  tagId?: string;
  questionData: InfiniteHookResponse<GetIaAlbumCenterPlaylistDefaultRes>;
}): IaPlaylistQuestionData => {
  const {
    data,
    mutate: reloadSummary,
    isLoading,
  } = useQuizResultSummary({ quizId, tagId });

  const {
    dataset: playlistQuestions,
    isLoading: questionsLoading,
    mutate: reloadQuestions,
  } = questionData;

  const isChallenge = !!data?.data.challenge;
  const isChallengeTaken = !!data?.data.challenge?.isTaken;
  const isChallengeExpired =
    data?.data.challenge?.status === ChallengeStatus.Expired;
  const isIncompleteMock =
    data?.data.quiz.mode === ExamMode.MockExam && !data?.data.quiz.isCompleted;
  const playlistData = data?.data;
  const playlistPath = getPlaylistPath(playlistData);
  const sectionViewType = playlistData?.quiz?.sectionViewType;

  const [optimisticUpdateQuestions, setOptimisticUpdateQuestions] = useState<
    IaPlaylistQuestionDataItem[] | null
  >();

  const questions = useMemo<IaPlaylistQuestionDataItem[]>(() => {
    if (optimisticUpdateQuestions) return optimisticUpdateQuestions;
    if (!playlistQuestions || !data) return [];
    return playlistQuestions.map((item) => ({
      isSkipped: item === null || item.isAnswered === null,
      isAnswered: item.isAnswered,
      isCorrect: item.practiceResult === PracticeResult.Correct,
      isOvertime: item.overtimeStatus === OvertimeStatus.Overtime,
      questionContent: item.questionContent,
      note: item.note,
      questionNumber: item.questionNumber,
      id: item.quizQuestionId,
      sourceType: item.sourceType,
      emoji: item.emoji,
      metadata: {
        questionId: item.quizQuestionId,
      },
      difficulty: {
        isDynamicDifficulty: item.isDynamicDifficulty,
        dynamicDifficulty: item.dynamicDifficulty,
        staticDifficulty: item.staticDifficulty,
      },
      correctRate: item.correctRate,
      overtimeRate: item.overtimeRate,
      status: item.status,
    }));
  }, [data, optimisticUpdateQuestions, playlistQuestions]);

  const reloadData: IaPlaylistQuestionData['reloadData'] = async ({
    optimisticQuestions,
    mutatorPromise,
  }) => {
    setOptimisticUpdateQuestions(optimisticQuestions);
    await mutatorPromise;
    await reloadSummary();
    await reloadQuestions();
    setOptimisticUpdateQuestions(null);
  };

  return {
    sectionViewType,
    data: playlistData,
    reloadData,
    state:
      !data || isLoading || questionsLoading
        ? 'loading'
        : questions.length === 0
        ? 'empty'
        : 'reachedEnd',
    questions: questions,
    totalCount: questions.length,
    playlistPath,
    hideQuestion:
      (isChallenge && !isChallengeTaken && !isChallengeExpired) ||
      isIncompleteMock,
    isTaken: !!data?.data.quiz.lastTakenAt,
  };
};

export const useIaPlaylistQuestionDataForLoginView = ({
  quizId,
}: {
  quizId: string;
}): IaPlaylistQuestionData => {
  const { userId } = useAuth();
  const { keyword, sort, filter } = useSearchFilterSort('playlist');
  const questionData = useIaPlaylists(
    {
      userId,
      search: `quizId:${quizId};${filter?.search || ''}`,
      searchFields: `quizId:eq;${filter?.searchFields || ''}`,
      ...sort,
      filter: 'questionContent;isAnswered;sourceType',
      viewSlug: PlaylistViewSlug.AlbumCenterPlaylistDefault,
      keyword,
    },
    {
      enable: !!quizId && !!userId,
    }
  );

  return useIaPlaylistQuestionData({ quizId, questionData });
};

export const useIaPlaylistQuestionDataForSharedView = ({
  quizId,
}: {
  quizId: string;
}): IaPlaylistQuestionData => {
  const {
    data,
    mutate: reloadSummary,
    isLoading,
  } = useQuizResultSummary({ quizId });
  const { data: selfData } = useQuizResultSummary({ quizId }); // in shared view, we need to get personal data without passing sid

  const {
    dataset: playlistQuestions,
    isLoading: questionsLoading,
    mutate: reloadQuestions,
  } = useIaPlaylists(
    {
      search: `quizId:${quizId};`,
      searchFields: `quizId:eq;`,
      filter: 'questionContent;isAnswered;sourceType',
      viewSlug: PlaylistViewSlug.AlbumCenterPlaylistDefault,
    },
    {
      enable: !!quizId,
    }
  );

  const isChallenge = !!data?.data.challenge;
  const isChallengeTaken = !!selfData?.data.challenge?.isTaken;
  const isChallengeExpired =
    selfData?.data.challenge?.status === ChallengeStatus.Expired;
  const isIncompleteMock =
    data?.data.quiz.mode === ExamMode.MockExam && !data?.data.quiz.isCompleted;

  const questionData = data?.data;

  const questionIds = useMemo(
    () => questionData?.quizQuestions.map((q) => q.quizQuestion.id) || [],
    [questionData?.quizQuestions]
  );
  // for shared view, emoji data need to be collected by another api because the original api returned the emoji tagged by the creator, not viewer
  const { data: emojiData, mutate: reloadEmoji } =
    useQuizQuestionMarkedEmojiMultiple(questionIds);

  const playlistPath = getPlaylistPath(questionData);
  const sectionViewType = questionData?.quiz?.sectionViewType;

  const [optimisticUpdateQuestions, setOptimisticUpdateQuestions] = useState<
    IaPlaylistQuestionDataItem[] | null
  >();

  const questions = useMemo(() => {
    if (optimisticUpdateQuestions) return optimisticUpdateQuestions;

    if (!playlistQuestions || !data) {
      return [];
    }

    return playlistQuestions.map((question) => ({
      isSkipped: question === null || question.isAnswered === null,
      isAnswered: question.isAnswered,
      isCorrect: question.practiceResult === PracticeResult.Correct,
      isOvertime: question.overtimeStatus === OvertimeStatus.Overtime,
      questionContent: question.questionContent,
      questionNumber: question.questionNumber,
      id: question.quizQuestionId,
      sourceType: question.sourceType,
      emoji: emojiData?.[question.quizQuestionId]?.emoji || null,
      metadata: {
        questionId: question.quizQuestionId,
      },
      difficulty: {
        isDynamicDifficulty: question.isDynamicDifficulty,
        dynamicDifficulty: question.dynamicDifficulty,
        staticDifficulty: question.staticDifficulty,
      },
      correctRate: question.correctRate,
      overtimeRate: question.overtimeRate,
    }));
  }, [data, emojiData, optimisticUpdateQuestions, playlistQuestions]);

  const filteredQuestions = useFrontendFilteredQuestions(questions);
  const reloadData: IaPlaylistQuestionData['reloadData'] = async ({
    optimisticQuestions,
    mutatorPromise,
  }) => {
    setOptimisticUpdateQuestions(optimisticQuestions);
    await mutatorPromise;
    await reloadSummary();
    await reloadEmoji();
    await reloadQuestions();
    setOptimisticUpdateQuestions(null);
  };

  return {
    sectionViewType,
    data: questionData,
    reloadData,
    state:
      !data || isLoading || questionsLoading
        ? 'loading'
        : questions.length === 0
        ? 'empty'
        : 'reachedEnd',
    questions: filteredQuestions,
    totalCount: questions.length,
    playlistPath,
    hideQuestion:
      (isChallenge && !isChallengeTaken && !isChallengeExpired) ||
      isIncompleteMock,
    isTaken: !!data?.data.quiz.lastTakenAt,
  };
};

export const useIaPlaylistQuestionDataForDynamicView = (
  {
    ignoreFilters,
  }: {
    ignoreFilters: boolean;
  } = { ignoreFilters: false }
): IaPlaylistQuestionData => {
  const { isFilterMistake, isFilterOvertime, isFilterEmoji } =
    usePlaylistFilter();
  const { markId, sectionId, dynamicType, customDate, isCommon } =
    usePlaylistInfo();
  const clubSlug = useClubSlug();
  const { data, mutate: reloadSummary } = usePlaylistSummary();

  const sectionViewType = data?.data.quiz.sectionViewType;
  const playlistPath = getPlaylistPath(data?.data);

  const {
    dataset,
    setSize,
    isEmpty,
    isLoadingInitialData,
    isLoading,
    pageCount,
    isLoadingMore,
    isReachingEnd,
    totalCount,
    mutate: reloadQuestions,
  } = useQuizDynamicQuestionList({
    sectionId,
    dynamicType: dynamicType || null,
    sourceId: markId,
    pageSize: 100,
    isFilterMistake: !ignoreFilters && isFilterMistake,
    isFilterOvertime: !ignoreFilters && isFilterOvertime,
    isFilterEmoji: !ignoreFilters && isFilterEmoji,
    customDate,
    isCommon,
    clubSlug,
  });

  const [optimisticUpdateQuestions, setOptimisticUpdateQuestions] = useState<
    IaPlaylistQuestionDataItem[] | null
  >();

  const questions = useMemo(() => {
    if (optimisticUpdateQuestions) {
      return dynamicType === QuizGroupType.Emoji
        ? optimisticUpdateQuestions.filter((item) => item.emoji?.id === markId)
        : optimisticUpdateQuestions;
    }

    return dataset.map((item) => ({
      isSkipped: item.quizResult === null,
      isAnswered: !!item.quizResult,
      isCorrect: !!item.quizResult?.isCorrect,
      isOvertime: !!item.quizResult?.isOvertime,
      questionContent: item.quizQuestion.question,
      questionNumber: item.quizQuestion.displayOrder + 1,
      id:
        dynamicType === QuizGroupType.Hashtags // in hashtag questions, there is no question id
          ? String(item.quizQuestion.displayOrder)
          : item.quizQuestion.id,
      sourceType: item.quizQuestion.sourceType,
      emoji: item.quizQuestion.emoji,
      metadata: {
        questionId: item.quizQuestion.id,
        examQuestionId: item.quizQuestion.examQuestionId,
        materialSetId: item.quizQuestion.materialSetId,
        sectionId,
      },
      difficulty: {
        isDynamicDifficulty: item.quizQuestion.isDynamicDifficult,
        dynamicDifficulty: item.quizQuestion.dynamicDifficulty,
        staticDifficulty: item.quizQuestion.staticDifficulty,
      },
      correctRate: item.quizQuestion.correctRate,
      overtimeRate: item.quizQuestion.overtimeRate,
    }));
  }, [dataset, dynamicType, markId, optimisticUpdateQuestions, sectionId]);

  const reloadData: IaPlaylistQuestionData['reloadData'] = async ({
    optimisticQuestions,
    mutatorPromise,
  }) => {
    setOptimisticUpdateQuestions(optimisticQuestions);
    await mutatorPromise;
    await reloadSummary();
    await reloadQuestions();
    setOptimisticUpdateQuestions(null);
  };

  return {
    sectionViewType,
    data: data?.data,
    reloadData,
    questions,
    setQuestionsSize: setSize,
    state: isEmpty
      ? 'empty'
      : isLoadingInitialData || isLoading || isLoadingMore
      ? 'loading'
      : isReachingEnd
      ? 'reachedEnd'
      : 'notReachedEnd',
    questionsPageCount: pageCount,
    totalCount,
    playlistPath,
    hideQuestion: false,
    isTaken: !!data?.data.quiz.lastTakenAt,
  };
};
