import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers';
import ThreadSearchUsers from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers/ThreadSearchUsers';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  EmphasizeButton,
  useBaseRightPanel,
} from '@front/ui';
import { apis } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import { call } from '@lib/web/utils';
import { uniq } from 'lodash';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    '.search-bar': {
      px: 1.5,
    },
  },
  scroll: {
    px: 0,
  },
};

export default function ThreadAddMemberPanel() {
  const { t } = useTranslation('thread');
  const { getRightParams, rightPanelHistory } =
    useBaseRightPanel<GlobalPanelParams>();
  const { getChannelInformation } = useChannelInformation();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const { channel } = getRightParams(GlobalPanelKeys.GlobalThreadAddMember);
  const { channelMemberIds } = getChannelInformation(channel);

  const [selected, setSelected] = useState<
    ThreadSearchUserIconListLayoutItemObj[]
  >([]);
  const [addingMember, setAddingMember] = useState(false);

  const handleAddMemberClick = async () => {
    setAddingMember(true);

    const selectedMemberIds: string[] = uniq(
      selected.map((item) => item.metadata?.threadMemberIds || []).flat()
    );

    const unAddedMemberIds = selectedMemberIds.filter(
      (id) => !channelMemberIds.includes(id)
    );

    const [res] = await call(
      apis.thread.inviteMember({
        channelType: channel.type as 'team' | 'public',
        channelId: channel.id as string,
        memberIds: unAddedMemberIds,
      })
    );

    if (res) {
      await call(
        apis.thread.systemMessageMemberInvited({
          channelType: channel.type as 'team' | 'public',
          channelId: channel.id as string,
          memberIds: unAddedMemberIds,
        })
      );
    }

    if (res?.data.afterInvitedDmViewId) {
      await maybeRedirectUserToNewDmView({
        channel,
        newViewId: res.data.afterInvitedDmViewId,
      });
    }

    setAddingMember(false);
    rightPanelHistory.back();
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherAddFriendIcon />}
      title={t('rhs.addMember.title')}
      resizable
      actionComponent={
        <EmphasizeButton
          onClick={handleAddMemberClick}
          disabled={selected.length === 0}
          loading={addingMember}
        >
          {t('rhs.addMember.button')}({selected.length})
        </EmphasizeButton>
      }
    >
      <Box sx={styles.main}>
        <BaseLayoutScrollContainer fullWidth sx={styles.scroll}>
          <ThreadSearchUsers
            sx={styles.content}
            selected={selected}
            setSelected={setSelected}
            preselectedMemberIds={channelMemberIds}
            preselectedDescription={t('rhs.addMember.alreadyInThread')}
          />
        </BaseLayoutScrollContainer>
      </Box>
    </BaseLayoutRightPanel>
  );
}
