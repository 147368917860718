import { FilterConditionConfig } from '@lib/ia/src/filter/types';
import { SortCriteriaConfig } from '@lib/ia/src/sort/types';
import {
  ChallengeFilter,
  ExamMode,
  QuizGroupType,
  SummaryTrendRecordType,
} from '@lib/web/apis';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import { SuggestionUser } from '../components/SuggestionPanel/types';
import { AwardBadgeDataItem } from '../ia/award/types';
import { SupportedAnswerFormatType } from '../widgets/ClubsPage/EditClub/contexts/type';
import { SetGoalFormFields } from '../widgets/CommonPanels/SetGoalPanel/config';

import { VirtualRouter } from './router';

type ReturnPanelParams = {
  returnPanel?: string;
  returnParams?: Record<string, any>;
};
export enum GlobalPanelKeys {
  GlobalGoalRing = 'GlobalGoalRing',
  GlobalClubInvitation = 'GlobalClubInvitation',
  GlobalHashtagsDetail = 'GlobalHashtagsDetail',
  GlobalEmojiDetail = 'GlobalEmojiDetail',
  GlobalFindUser = 'GlobalFindUser',
  GlobalProfile = 'GlobalProfile',
  GlobalProfileAwards = 'GlobalProfileAwards',
  GlobalFollowers = 'GlobalFollowers',
  GlobalFollowing = 'GlobalFollowing',
  GlobalClubs = 'GlobalClubs',
  GlobalLayout = 'GlobalLayout',
  GlobalFilter = 'GlobalFilter',
  GlobalSort = 'GlobalSort',
  GlobalRankings = 'Rankings',
  GlobalManageAgents = 'GlobalManageAgents',
  GlobalManageAgentsSearch = 'GlobalManageAgentsSearch',
  GlobalAgentProfile = 'GlobalAgentProfile',
  GlobalClubMembers = 'GlobalClubMembers',
  GlobalAddFriends = 'GlobalAddFriends',
  GlobalThreadChat = 'GlobalThreadChat',
  GlobalThreadCreatorFromViewPanel = 'GlobalThreadCreatorFromViewPanel',
  GlobalThreadCreatorFromMessagePanel = 'GlobalThreadCreatorFromMessagePanel',
  GlobalThreadView = 'GlobalThreadView',
  GlobalThreadProperties = 'GlobalThreadProperties',
  GlobalThreadMembers = 'GlobalThreadMembers',
  GlobalThreadActiveMembers = 'GlobalThreadActiveMembers',
  GlobalThreadAddMember = 'GlobalThreadAddMember',
  GlobalStreaks = 'GlobalStreaks',
  GlobalAwardDetail = 'GlobalAwardDetail',
  GlobalDirectToShare = 'GlobalDirectToShare',
  GlobalSetGoal = 'GlobalSetGoal',
  GlobalFollowMore = 'GlobalFollowMore',
  GlobalStartChallenge = 'GlobalChallenge',
  GlobalChallengeRank = 'GlobalChallengeRank',
  GlobalAddChallenger = 'GlobalAddChallenger',
  GlobalPlaylistDetail = 'GlobalPlaylistDetail',
}
export type GlobalPanelParams = {
  [GlobalPanelKeys.GlobalGoalRing]: {
    date: string;
    mockExamRoundCount: { current: number; ratio: number; target: number };
    practiceTimeMinute: { current: number; ratio: number; target: number };
    questionCount: { current: number; ratio: number; target: number };
  };
  [GlobalPanelKeys.GlobalClubInvitation]: { defaultSearch?: string };
  [GlobalPanelKeys.GlobalHashtagsDetail]: ReturnPanelParams & {
    category: string;
    tagId: string;
    title: string;
  };
  [GlobalPanelKeys.GlobalFindUser]: undefined;
  [GlobalPanelKeys.GlobalProfile]: {
    userId?: string;
    memberId?: string;
  };
  [GlobalPanelKeys.GlobalProfileAwards]: {
    userId?: string;
  };
  [GlobalPanelKeys.GlobalFollowers]: {
    userId: string;
  };
  [GlobalPanelKeys.GlobalFollowing]: {
    userId: string;
  };
  [GlobalPanelKeys.GlobalClubs]: {
    userId: string;
  };
  [GlobalPanelKeys.GlobalLayout]: {
    supportableLayouts: {
      table?: boolean;
      channel?: boolean;
    };
  };
  [GlobalPanelKeys.GlobalFilter]: {
    selectedCondition?: FilterConditionConfig;
    page?: 'querySummary' | 'fieldList' | 'conditionDetail';
    showEmojiPicker?: boolean;
  };
  [GlobalPanelKeys.GlobalSort]: {
    selectedCriteria?: SortCriteriaConfig;
    page?: 'sortSummary' | 'fieldList' | 'criteriaDetail';
  };
  [GlobalPanelKeys.GlobalRankings]: {
    tabIndex?: number;
    clubSlug?: string;
  };
  [GlobalPanelKeys.GlobalManageAgents]: {
    highlightAgentId?: string;
  };
  [GlobalPanelKeys.GlobalManageAgentsSearch]: undefined;
  [GlobalPanelKeys.GlobalAgentProfile]: ReturnPanelParams & {
    agentId: string;
  };
  [GlobalPanelKeys.GlobalClubMembers]: undefined;
  [GlobalPanelKeys.GlobalAddFriends]: {
    defaultSearch?: string;
    activeTab?: number;
  };
  [GlobalPanelKeys.GlobalThreadChat]: {
    channelCid: string;
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [GlobalPanelKeys.GlobalThreadCreatorFromViewPanel]: {
    tmpId: string;
    view: GetThreadViewRes;
  };
  [GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel]: {
    tmpId: string;
    channel: Channel;
    message: StreamMessage;
  };
  [GlobalPanelKeys.GlobalThreadView]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [GlobalPanelKeys.GlobalThreadProperties]: {
    channelCid: string;
  };
  [GlobalPanelKeys.GlobalThreadMembers]: {
    channel: Channel;
  };
  [GlobalPanelKeys.GlobalThreadActiveMembers]: {
    channel: Channel;
  };
  [GlobalPanelKeys.GlobalThreadAddMember]: {
    channel: Channel;
  };
  [GlobalPanelKeys.GlobalStreaks]: {
    type: 'streak' | 'longestStreak' | 'selectedStreak';
    longestStreakEndDate?: Date;
    selectedStreakDate?: Date;
    clubSlug?: string;
  };
  [GlobalPanelKeys.GlobalAwardDetail]: {
    awardItem: AwardBadgeDataItem;
  };
  [GlobalPanelKeys.GlobalDirectToShare]: {
    autoFocus?: boolean;
    virtualRouter?: VirtualRouter;
    customTitle?: string;
  };
  [GlobalPanelKeys.GlobalSetGoal]: {
    target?: string;
    focusField?: SetGoalFormFields;
    onSubmit?: () => void;
  };
  [GlobalPanelKeys.GlobalFollowMore]: undefined;
  [GlobalPanelKeys.GlobalStartChallenge]: {
    challengerSource?: SuggestionUser[];
  };
  [GlobalPanelKeys.GlobalChallengeRank]: {
    quizId?: string;
    challengeId: string;
  };
  [GlobalPanelKeys.GlobalAddChallenger]: {
    challengeId: string;
  };
  [GlobalPanelKeys.GlobalPlaylistDetail]: {
    quizId?: string;
    roundNo?: number;
    tag?: {
      tagId: string;
      tagCode: string;
    };
    dynamicType?: QuizGroupType.Hashtags; // for now, only Hashtag is accepted
    selectedFilter?: 'mistakes' | 'overtime';
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
};

export enum EditorPanelKeys {
  EditorAnalytics = 'EditorAnalytics',
  EditorSolution = 'EditorSolution',
  EditorThreadSolution = 'EditorThreadSolution',
  EditorRubric = 'EditorRubric',
  EditorRubricCriteria = 'EditorRubricCriteria',
  EditorHashtags = 'EditorHashtags',
  EditorDifficulty = 'EditorDifficulty',
  EditorFormula = 'EditorFormula',
  EditorSettings = 'EditorSettings',
  EditorCopyTo = 'EditorCopyTo',
  EditorQuestionPreview = 'EditorQuestionPreview',
}

export type EditorPanelParams = {
  [EditorPanelKeys.EditorAnalytics]: {
    questionId: string;
  };
  [EditorPanelKeys.EditorSolution]: {
    questionId: string;
    keepMounted?: boolean;
    readonly?: boolean;
  };
  [EditorPanelKeys.EditorRubric]: {
    questionId: string;
    readonly?: boolean;
  };
  [EditorPanelKeys.EditorRubricCriteria]: {
    questionId: string;
    criteriaId: string;
  };
  [EditorPanelKeys.EditorThreadSolution]: {
    questionId: string;
    keepMounted?: boolean;
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [EditorPanelKeys.EditorHashtags]: {
    questionId: string;
    readonly?: boolean;
  };
  [EditorPanelKeys.EditorDifficulty]: {
    questionId: string;
    readonly?: boolean;
  };
  [EditorPanelKeys.EditorFormula]: { keepMounted?: boolean };
  [EditorPanelKeys.EditorSettings]: {
    questionId: string;
    readonly?: boolean;
  };
  [EditorPanelKeys.EditorCopyTo]: {
    questionIds: string[];
    onFinished?: () => void;
  };
  [EditorPanelKeys.EditorQuestionPreview]: {
    questionId: string;
    pointOfView: 'createQuestion' | 'reviewQuestion';
    reviewUserId?: string;
  };
};

export enum PlaylistPanelKeys {
  PlaylistHashtags = 'PlaylistHashtags',
  PlaylistUsers = 'PlaylistUsers',
  PlaylistEmoji = 'PlaylistEmoji',
  PlaylistChallengeFriends = 'PlaylistChallengeFriends',
}
export type PlaylistPanelParams = GlobalPanelParams & {
  [PlaylistPanelKeys.PlaylistHashtags]: undefined;
  [PlaylistPanelKeys.PlaylistEmoji]: {
    emojiId: string;
    emojiCode: string;
    emojiName: string;
    onChange: (params: {
      changedEmoji: { id: string; code: string; name: string };
    }) => void;
  };
};

export const enum CheckoutPanelKeys {
  Checkout = 'Checkout',
  DiscountCode = 'DiscountCode',
  PaymentLink = 'PaymentLink',
}

export type CheckoutPanelParams = {
  [CheckoutPanelKeys.Checkout]: undefined;
  [CheckoutPanelKeys.DiscountCode]: undefined;
  [CheckoutPanelKeys.PaymentLink]: undefined;
};

export const enum ProfilePanelKeys {
  ProfileShareDiscount = 'ProfileShareDiscount',
  ProfileAddDiscount = 'ProfileAddDiscount',
  ProfileAhaPoint = 'ProfileAhaPoint',
}

export type ProfilePanelParams = {
  [ProfilePanelKeys.ProfileShareDiscount]: undefined;
  [ProfilePanelKeys.ProfileAddDiscount]: undefined;
  [ProfilePanelKeys.ProfileAhaPoint]: undefined;
};

export const enum ChallengesPanelKeys {
  ChallengesFilter = 'ChallengeFilter',
}

export type ChallengesPanelParams = {
  [ChallengesPanelKeys.ChallengesFilter]: {
    challengeFilters?: ChallengeFilter[];
  };
};

export const enum QuizPanelKeys {
  QuizAnalytics = 'QuizAnalytics',
  QuizSolution = 'QuizSolution',
  QuizRubric = 'QuizRubric',
  QuizThreadSolution = 'QuizThreadSolution',
  QuizHashtag = 'QuizHashtag',
  QuizNote = 'QuizNote',
  QuizFormula = 'QuizFormula',
  QuizQuestionPreview = 'QuizQuestionPreview',
}

export type QuizPanelParams = GlobalPanelParams & {
  [QuizPanelKeys.QuizAnalytics]: undefined;
  [QuizPanelKeys.QuizSolution]: undefined;
  [QuizPanelKeys.QuizRubric]: undefined;
  [QuizPanelKeys.QuizThreadSolution]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [QuizPanelKeys.QuizHashtag]: undefined;
  [QuizPanelKeys.QuizNote]: undefined;
  [QuizPanelKeys.QuizFormula]: undefined;
  [QuizPanelKeys.QuizQuestionPreview]: {
    shortId: string;
    quizId: string;
    roundNo: number;
    questionNo: number;
    mode: ExamMode;
  };
};

export const enum QuizResultPanelKeys {
  QuizResultHashtags = 'QuizResultHashtags',
  QuizChallengeFriends = 'QuizChallengeFriends',
}

export type QuizResultPanelParams = {
  [QuizResultPanelKeys.QuizResultHashtags]: undefined;
  [QuizResultPanelKeys.QuizChallengeFriends]: undefined;
};

export const enum AccountPanelKeys {
  JoinedClubs = 'JoinedClubs',
  Following = 'Following',
  Followers = 'Followers',
}

export type AccountPanelParams = {
  [AccountPanelKeys.JoinedClubs]: undefined;
  [AccountPanelKeys.Following]: undefined;
  [AccountPanelKeys.Followers]: undefined;
};

export const enum ClubSummaryPanelKeys {
  Calendar = 'Calendar',
}

export type ClubSummaryParams = {
  [ClubSummaryPanelKeys.Calendar]:
    | undefined
    | {
        type?: 'default' | 'trends';
        trendType?: SummaryTrendRecordType;
      };
};

export const enum QBankReporterPanelKeys {
  EditVariable = 'EditVariable',
}

export type QBankReporterPanelParams = {
  [QBankReporterPanelKeys.EditVariable]: {
    shortId: string;
    variable: QuizQuestionVariable;
  };
};

export const enum MarketplaceKeys {
  MarketplaceDetail = 'MarketplaceDetail',
  MarketplaceShare = 'MarketplaceShare',
}

export type MarketplacePanelParams = {
  [MarketplaceKeys.MarketplaceDetail]?: {
    product: GetSanityProductRes;
  };
  [MarketplaceKeys.MarketplaceShare]: undefined;
};

export const enum EditClubPanelKeys {
  EditClubFormats = 'EditClubFormats',
  EditClubMembers = 'EditClubMembers',
  EditClubInviteMembers = 'EditClubInviteMembers',
}

export type EditClubPanelParams = {
  [EditClubPanelKeys.EditClubFormats]: {
    answerFormat: SupportedAnswerFormatType;
  };
  [EditClubPanelKeys.EditClubMembers]: undefined;
  [EditClubPanelKeys.EditClubInviteMembers]: undefined;
};

export const enum EditClubGroupPanelKeys {
  EditClubGroupAddClubs = 'EditClubGroupAddClubs',
}

export type EditClubGroupPanelParams = {
  [EditClubGroupPanelKeys.EditClubGroupAddClubs]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
};

export const enum EditAgentPanelKeys {
  EditAgentPrompts = 'EditAgentPrompts',
  EditAgentEmojiPicker = 'EditAgentEmojiPicker',
  EditAgentAiModel = 'EditAgentAiModel',
  EditAgentDataSourceAddPlaylists = 'EditAgentDataSourceAddPlaylists',
  EditAgentDataSourceAddQuestions = 'EditAgentDataSourceAddQuestions',
  EditAgentDataSourceAddLinks = 'EditAgentDataSourceAddLinks',
  EditAgentDataSourceAddFiles = 'EditAgentDataSourceAddFiles',
}

export type EditAgentPanelParams = {
  [EditAgentPanelKeys.EditAgentPrompts]: {
    promptId: string;
  };
  [EditAgentPanelKeys.EditAgentEmojiPicker]: ReturnPanelParams & {
    emoji: string;
    onSelected: (emoji: string) => void;
  };
  [EditAgentPanelKeys.EditAgentAiModel]: {
    aiModel: GetIaAiModelCardCenterLlmDefaultRes;
  };
  [EditAgentPanelKeys.EditAgentDataSourceAddPlaylists]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [EditAgentPanelKeys.EditAgentDataSourceAddQuestions]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [EditAgentPanelKeys.EditAgentDataSourceAddLinks]: {
    rhsId: string;
    afterAddedLink: () => Promise<void> | void;
  };
  [EditAgentPanelKeys.EditAgentDataSourceAddFiles]: {
    rhsId: string;
    afterUploadedFile: () => Promise<void> | void;
  };
};

export const enum ThreadPanelKeys {
  DmThreadNewThread = 'DmThreadNewThread',
  ThreadViewPanel = 'ThreadViewPanel',
  ThreadEmoji = 'ThreadEmoji',
}

export type ThreadPanelParams = {
  [ThreadPanelKeys.DmThreadNewThread]: {
    defaultMessageText?: string;
  };
  [ThreadPanelKeys.ThreadViewPanel]: {
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [ThreadPanelKeys.ThreadEmoji]: {
    emojiId: string;
    emojiCode: string;
    emojiName: string;
    onChange: (params: {
      changedEmoji: { id: string; code: string; name: string };
    }) => void;
  };
};

export enum DiscountCodePanelKeys {
  DiscountCodeDetail = 'DiscountCodeDetail',
  CreateDiscountCode = 'CreateDiscountCode',
}

export type DiscountCodePanelParams = {
  [DiscountCodePanelKeys.DiscountCodeDetail]: {
    id: string;
    reloadList?: () => void;
  };
  [DiscountCodePanelKeys.CreateDiscountCode]: {
    reloadList?: () => void;
  };
};

export enum PrivatePanelKeys {
  CreateQuiz = 'CreateQuiz',
}

export type PrivatePanelParams = {
  [PrivatePanelKeys.CreateQuiz]: { isChallengeMode?: boolean };
};
