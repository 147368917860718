import { useMemo } from 'react';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';

const viewDetailToIconListLayoutItemObj = (
  data: ReturnType<
    ReturnType<typeof useThreadViewDetails>['getThreadViewDetail']
  >,
  preselectedMemberIds: string[] = [],
  preselectedDescription?: string
): ThreadSearchUserIconListLayoutItemObj => {
  const allMemberPreselected = data.viewMemberIds.every((id) =>
    preselectedMemberIds.includes(id)
  );

  return {
    id: data.view?.id || '',
    title: data.viewName,
    description: allMemberPreselected ? preselectedDescription : undefined,
    avatarTitle: data.viewIcon?.avatarTitle,
    avatarUrl: data.viewIcon?.avatar, // TODO: when we support view name in dm, we need to handle that here
    avatarBadgeCount: data.viewIcon?.avatarBadgeCount,
    actionMap: {
      select: {
        value: 'itemSelected',
      },
      hover: {
        value: 'itemHovered',
      },
    },
    metadata: {
      category: 'group',
      threadMemberIds: data.viewMemberIds,
    },
  };
};

export const useSearchGroups = ({
  debouncedSearch,
  preselectedMemberIds,
  preselectedDescription,
}: {
  debouncedSearch: string;
  preselectedMemberIds?: string[];
  preselectedDescription?: string;
}) => {
  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();
  // get group member number,  view name, view icon
  const initialDataset = useMemo(
    () =>
      views
        .map((view) => getThreadViewDetail(view))
        .filter((viewDetail) => viewDetail.viewMemberIds.length > 2)
        .map((data) =>
          viewDetailToIconListLayoutItemObj(
            data,
            preselectedMemberIds,
            preselectedDescription
          )
        ),
    [getThreadViewDetail, preselectedDescription, preselectedMemberIds, views]
  );
  const searchingDataset = useMemo(
    () => initialDataset.filter((item) => item.title.includes(debouncedSearch)),
    [debouncedSearch, initialDataset]
  );

  return useMemo(
    () => ({
      initialDataset,
      searchingDataset,
    }),
    [initialDataset, searchingDataset]
  );
};
