import { useCallback, useRef } from 'react';
import { ComposerBlock, TextComposer } from '@lib/web/composer';
import { TextComposerProvider } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { extractTextFromHtml } from '@lib/web/utils';

import { ThreadComposerProvider } from '../../contexts';
import { useRenderThreadMessageToHtml } from '../../hooks/message/useRenderThreadMessageToHtml';
import useThreadMentionItems from '../../ThreadComposer/hooks/useThreadMentionItems';
import { threadBlockEditingStyles } from '../../ThreadTextComposer/config/threadComposerBlockStyles';
import { threadComposerSchema } from '../../ThreadTextComposer/config/threadComposerSchema';
import { threadTiptapExtensions } from '../../ThreadTextComposer/config/threadTiptapExtensions';

export type ThreadMessageProps = {
  defaultBlocks?: ComposerBlock[];
  handleKeyDown?: (
    event: KeyboardEvent,
    text: string,
    changedBlocks: ComposerBlock[]
  ) => void;
  handleBlur?: (event: FocusEvent) => void;
};

export default function ThreadMessageComposer({
  defaultBlocks,
  handleKeyDown,
  handleBlur,
}: ThreadMessageProps) {
  const changedBlocksRef = useRef<ComposerBlock[]>([]);
  const { dataset: mentionItems, setQueryText: setMentionQueryText } =
    useThreadMentionItems([]);
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();

  const handleBlockChange = useCallback((blocks: ComposerBlock[]): void => {
    changedBlocksRef.current = blocks;
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      // To pass latest changed after block changed event
      setTimeout(() => {
        const html = renderThreadMessageToHtml(
          undefined,
          changedBlocksRef.current
        );
        const plainText = extractTextFromHtml(html);
        handleKeyDown?.(event, plainText, changedBlocksRef.current);
      });
    },
    [handleKeyDown, renderThreadMessageToHtml]
  );

  return (
    <ThreadComposerProvider text={''} setText={() => {}}>
      <TextComposerProvider>
        <TextComposer
          blockStyles={threadBlockEditingStyles}
          blockSchema={threadComposerSchema}
          customTiptapExtensions={threadTiptapExtensions}
          defaultBlocks={defaultBlocks}
          onBlocksChange={handleBlockChange}
          mentionItems={mentionItems}
          onMentionQueryChange={setMentionQueryText}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          enableToolbar={false}
          enableSlashMenu={false}
          enableSideMenu={false}
          placeholder=""
        />
      </TextComposerProvider>
    </ThreadComposerProvider>
  );
}
