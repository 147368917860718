import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useThreadSortFilter from '@app/web/src/hooks/utils/useThreadSortFilter';
import {
  BaseLayoutContainer,
  BaseLayoutScrollArea,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { FilterConditionConfig } from '@lib/ia/src/filter/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import TableLayoutActionsContext from '@lib/ia/src/layouts/TableLayout/TableLayoutActions/TableLayoutActionsContext';
import TableLayoutActionsProvider from '@lib/ia/src/layouts/TableLayout/TableLayoutActions/TableLayoutActionsProvider';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { QuizAudioProvider } from '@lib/web/practice';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';

import useSortActions from '../../../CommonPanels/SortResponsivePanel/hooks/useSortActions';
import useSetFilterSortConditions from '../../hooks/useSetFilterSortConditions';
import useThreadRender from '../../hooks/useThreadRender';
import ThreadMentionUser from '../ThreadMentionUser';
import ThreadViewTitle from '../ThreadViewTitle';

import { useThreadViewTableAvailableAction } from './hooks/useThreadViewTableAvailableAction';
import { useThreadViewTableLayoutConfig } from './hooks/useThreadViewTableLayoutConfig';

const styles = {
  pageTitle: {
    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    mb: '20px',
  },
};

export type ThreadViewTableLayoutProps = {
  view: GetThreadViewRes;
  clubId?: string;
  scope?: string;
  position?: 'rhs' | 'centre';
  extraFilterConditions?: FilterConditionConfig[];
};

function ThreadViewTableLayout({
  view,
  clubId,
  scope,
  position = 'centre',
  extraFilterConditions,
}: ThreadViewTableLayoutProps) {
  const { resetThreadChannelSortFilter } = useThread();
  const { viewMemberIds } = useThreadViewDetail(view);
  const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({});

  const { useChannelSortFilter } = useThreadSortFilter(scope);
  const {
    key,
    filters,
    resetFilter,
    sort,
    resetSort,
    completeInitPartial,
    isInitializing,
  } = useChannelSortFilter(view);

  const { reset: resetSortAction } = useSortActions();

  const { setDefaultSortCriteria } = useSetFilterSortConditions({
    type: 'table',
    view,
    viewMemberIds,
    scope,
    completeInitPartial,
    extraFilterConditions,
  });

  const channelsData = useFilteredChannels({
    scope: `${position}-table-view`,
    key,
    filters,
    sort: [{ ...sort, last_message_at: -1 }],
    enable: !isInitializing,
  });

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const config = useThreadViewTableLayoutConfig({
    channelsData,
    selectedRowId,
    expandedMap,
  });
  const availableActions = useThreadViewTableAvailableAction({
    view,
    setSelectedRowId,
    loadMore: () => channelsData.setSize((st) => st + 1),
    expandRow: ({ rowId, value }) =>
      setExpandedMap({ ...expandedMap, [rowId]: value }),
  });
  const renders = useThreadRender();

  useEffect(() => {
    resetThreadChannelSortFilter.current = (): void => {
      resetSort();
      resetSortAction();
      resetFilter();

      setDefaultSortCriteria(); // sort criteria will be clear, so set default sort criteria here
    };
  }, [
    resetFilter,
    resetSort,
    resetSortAction,
    resetThreadChannelSortFilter,
    setDefaultSortCriteria,
  ]);

  const { rightPanelOpened } = useBaseRightPanel();
  const { actionsRef: tableActionsRef } = useContext(TableLayoutActionsContext);

  useEffect(() => {
    if (position === 'rhs' && !rightPanelOpened) {
      tableActionsRef.current?.clearAllCheckbox();
    }
  }, [position, rightPanelOpened, tableActionsRef]);

  return (
    <BaseLayoutScrollArea>
      <BaseLayoutContainer sx={styles.pageTitle}>
        <ThreadViewTitle view={view} clubId={clubId} />
      </BaseLayoutContainer>
      <Box>
        <QuizAudioProvider>
          <IaRenderContextProvider value={renders}>
            <IaActionContextProvider availableActions={availableActions}>
              <EditorThreadComposerRenderer
                mentionRender={ThreadMentionUser}
                styleProvider
              >
                <IaLayouts layouts={config} />
              </EditorThreadComposerRenderer>
            </IaActionContextProvider>
          </IaRenderContextProvider>
        </QuizAudioProvider>
      </Box>
    </BaseLayoutScrollArea>
  );
}

export default function ThreadViewTableLayoutWrap(
  props: ThreadViewTableLayoutProps
) {
  return (
    <TableLayoutActionsProvider>
      <ThreadViewTableLayout {...props} />
    </TableLayoutActionsProvider>
  );
}
