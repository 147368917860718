import { useEffect, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useIaFilterValues from '@app/web/src/hooks/utils/useIaFilterValues';
import useIaSortValues from '@app/web/src/hooks/utils/useIaSortValues';
import {
  useIaPlaylistQuestionAvailableActions,
  useIaPlaylistQuestionData,
  useIaPlaylistQuestionDataForDynamicView,
} from '@app/web/src/ia/playlistQuestion';
import { IaPlaylistQuestionDataItem } from '@app/web/src/ia/playlistQuestion/types';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { PlaylistViewSlug, useAuth, useIaPlaylists } from '@lib/web/apis';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';

import ShareQuestionDropdown from '../../../PlaylistDetailPage/PlaylistDetail/components/ShareQuestionDropdown';
import { usePlaylistDetailTableLayouts } from '../../../PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistDetailTableLayouts';
import usePlaylistInfo from '../../../PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistInfo';
import { FILTER_SORT_SCOPE } from '../PlaylistDetailPanel';

const styles = {
  root: {
    '& .table-layout-inner': {
      width: '100%',
      minWidth: 363,
    },
  },
};

function NormalPlaylistQuestions() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { rightPanelOpened } = useBaseRightPanel<GlobalPanelParams>();

  const { userId } = useAuth();
  const { quizId, tagId } = usePlaylistInfo();
  const { searchQuery } = useIaFilterValues({
    scope: FILTER_SORT_SCOPE,
  });
  const { sortQuery } = useIaSortValues({
    scope: FILTER_SORT_SCOPE,
  });

  const questionData = useIaPlaylists(
    {
      userId,
      ...combineSearch(
        searchQuery,
        {
          search: `quizId:${quizId}`,
          searchFields: 'quizId:eq',
        },
        tagId
          ? {
              search: `hashtags:${tagId}`,
              searchFields: 'hashtags:eq',
            }
          : {}
      ),
      ...sortQuery,
      filter: 'questionContent;isAnswered;sourceType',
      viewSlug: PlaylistViewSlug.AlbumCenterPlaylistDefault,
    },
    {
      enable: !!quizId && !!userId,
    }
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [question, setQuestion] = useState<IaPlaylistQuestionDataItem>();
  const { isTargetPanelOpened } = useBaseRightPanel<GlobalPanelParams>();
  const selected = isTargetPanelOpened(GlobalPanelKeys.GlobalDirectToShare)
    ? question
    : undefined;

  const playlistQuestionData = useIaPlaylistQuestionData({
    quizId,
    tagId,
    questionData,
  });
  const layouts = usePlaylistDetailTableLayouts({
    playlistQuestionData,
    selected,
    settings: {
      minimizeNoteColumn: !mdUp,
      layoutSetting: {
        minWidth: mdUp ? 515 : 343,
        maxWidth: mdUp ? 515 : 343,
        position: 'rhs',
      },
      enablePreview: false,
    },
  });
  const availableActions = useIaPlaylistQuestionAvailableActions({
    playlistQuestionData,
    onShare: (q, el) => {
      setQuestion(q);
      setAnchorEl(el);
    },
  });

  const { setSize } = questionData;
  useEffect(() => {
    setSize(1);
  }, [setSize, rightPanelOpened]);

  return (
    <Box sx={styles.root}>
      <IaActionContextProvider availableActions={availableActions}>
        <EditorComposerRenderer styleProvider oneLine>
          <IaLayouts layouts={layouts} />
        </EditorComposerRenderer>
        <ShareQuestionDropdown
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(undefined)}
          question={question}
          playlistQuestionData={playlistQuestionData}
        />
      </IaActionContextProvider>
    </Box>
  );
}

function DynamicPlaylistQuestions() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const playlistQuestionData = useIaPlaylistQuestionDataForDynamicView();

  const layouts = usePlaylistDetailTableLayouts({
    playlistQuestionData,
    isDynamic: true,
    settings: {
      minimizeNoteColumn: !mdUp,
      layoutSetting: {
        minWidth: mdUp ? 515 : 343,
        maxWidth: mdUp ? 515 : 343,
        position: 'rhs',
      },
      enablePreview: false,
    },
  });
  const availableActions = useIaPlaylistQuestionAvailableActions({
    playlistQuestionData,
  });

  return (
    <Box sx={styles.root}>
      <IaActionContextProvider availableActions={availableActions}>
        <EditorComposerRenderer styleProvider oneLine>
          <IaLayouts layouts={layouts} />
        </EditorComposerRenderer>
      </IaActionContextProvider>
    </Box>
  );
}

export default function PlaylistQuestions() {
  const { dynamicType } = usePlaylistInfo();

  if (dynamicType) return <DynamicPlaylistQuestions />;
  return <NormalPlaylistQuestions />;
}
