import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import { Button, Scrollbar } from '@front/ui';
import SortCriteria from '@lib/ia/src/sort/SortCriteria';
import {
  SortCriteriaChangedEvent,
  SortCriteriaConfig,
} from '@lib/ia/src/sort/types';
import { v4 } from 'uuid';

import SortContext from '../context/SortContext';
import useSortActions from '../hooks/useSortActions';

const mobileStyles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '60px',
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    p: 1.5,
  },
  hidden: {
    display: 'none',
  },
};

const desktopStyles = {
  action: {
    display: 'flex',
    gap: 1,
    width: '100%',
    p: 1.5,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      flex: 1,
      px: 0,
      letterSpacing: 0,
    },
  },
  hidden: {
    display: 'none',
  },
};
export default function SortCriteriaContent() {
  const { t } = useTranslation();
  const [settings] = useContext(SortContext);
  const { selectedCriteria, scope } = settings;
  const { goPage, back } = useSortActions();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { upsertCriteria, removeCriteria } = useSort(scope);

  const [data, setData] = useState<SortCriteriaChangedEvent>();
  const criteria = data || selectedCriteria;

  const handleAddSort = () => {
    if (!criteria || !criteria.direction || !criteria.field) {
      return;
    }
    const currentCriteria: SortCriteriaConfig = {
      id: criteria.id || v4(),
      field: criteria.field,
      direction: criteria.direction,
    };
    upsertCriteria(currentCriteria);
    goPage({ page: 'sortSummary' });
  };

  const handleDeleteSort = () => {
    if (selectedCriteria && selectedCriteria.id) {
      removeCriteria(selectedCriteria.id);
    }
    back();
  };

  const handleChanged = (event: SortCriteriaChangedEvent) => {
    setData(event);
  };

  if (!selectedCriteria) return null;

  if (mdUp)
    return (
      <>
        <SortCriteria {...selectedCriteria} onChange={handleChanged} />
        <Box sx={desktopStyles.action}>
          <Button onClick={handleDeleteSort} variant="outlined" color="error">
            Delete Sort
          </Button>
          <Button onClick={handleAddSort}>
            {!criteria?.id ? t('Add Sort') : t('Update Sort')}
          </Button>
        </Box>
      </>
    );

  return (
    <>
      <Scrollbar sx={mobileStyles.scroll}>
        <SortCriteria {...selectedCriteria} onChange={handleChanged} />
      </Scrollbar>
      <Box sx={mobileStyles.action}>
        <Button onClick={handleAddSort}>
          {!criteria?.id ? t('Add Sort') : t('Update Sort')}
        </Button>
      </Box>
    </>
  );
}
