import { useMemo } from 'react';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItem } from '@lib/ia/src/layouts/IconListLayout/types';
import { MemberSearchCategory } from '@lib/web/apis';
import { TFunction } from 'i18next';

import { SuggestionPanelProps, SuggestionUser } from './types';
import {
  newUserToIconListItem,
  suggestionSelectedUserToIconListItem,
  suggestionUserToIconListItem,
} from './utils';

export default function useSuggestionLayoutConfig<OtherChipValue = string>({
  t,
  newUser,
  showResult,
  searchMode,
  dataset,
  datasetTotalCount,
  selectedItems,
  defaultItems,
  disableSelectTooltip,
  renderSelectedTitle,
  renderSelectedItems,
  renderDefaultTitle,
  renderDefaultItems,
  renderTitle,
  renderItems,
  renderNewUser,
}: {
  t: TFunction;
  newUser?: { email: string; actionText: string } | null;
  showResult: boolean;
  searchMode: MemberSearchCategory | OtherChipValue;
  dataset: SuggestionUser[];
  datasetTotalCount?: number;
  selectedItems: SuggestionPanelProps['selectedItems'];
  defaultItems?: SuggestionPanelProps['selectedItems'];
  disableSelectTooltip?: { title: string; content: string; icon: string };
  renderSelectedTitle?: SuggestionPanelProps['renderSelectedTitle'];
  renderSelectedItems?: SuggestionPanelProps['renderSelectedItems'];
  renderDefaultTitle?: SuggestionPanelProps['renderSelectedTitle'];
  renderDefaultItems?: SuggestionPanelProps['renderSelectedItems'];
  renderTitle?: SuggestionPanelProps['renderTitle'];
  renderItems?: SuggestionPanelProps['renderItems'];
  renderNewUser?: SuggestionPanelProps['renderNewUser'];
}) {
  return useMemo<IaLayoutConfig[]>(() => {
    if (newUser) {
      return [
        {
          layout: 'icon-list-layout',
          items: [
            renderNewUser?.(newUser, newUserToIconListItem(newUser)) ||
              newUserToIconListItem(newUser),
          ],
        },
      ];
    }

    const selectedIconList: IconListLayoutItem[] = selectedItems?.length
      ? [
          renderSelectedTitle?.(selectedItems.length) ||
            t('suggestion.search.count', {
              context: 'selected',
              count: selectedItems.length,
            }),
          ...selectedItems.map(
            renderSelectedItems || suggestionSelectedUserToIconListItem
          ),
        ]
      : [];

    if (showResult) {
      const output: IaLayoutConfig[] = [];
      const resultIconList: IconListLayoutItem[] = datasetTotalCount
        ? [
            renderTitle?.(datasetTotalCount) ||
              t('suggestion.search.count', {
                context: searchMode,
                count: datasetTotalCount,
              }),
            ...dataset.map(
              (data, i) =>
                renderItems?.(data, i) ||
                suggestionUserToIconListItem(data, searchMode)
            ),
          ]
        : [];

      if (selectedIconList.length) {
        output.push({
          layout: 'icon-list-layout',
          items: selectedIconList,
        });
      }

      if (resultIconList.length) {
        output.push({
          layout: 'icon-list-layout',
          settings: {
            disableSelectTooltip,
          },
          items: resultIconList,
        });
      }

      return output;
    }

    const output: IaLayoutConfig[] = [];
    const defaultIconList: IconListLayoutItem[] = defaultItems?.length
      ? [
          renderDefaultTitle?.(defaultItems.length) ||
            t('suggestion.search.count', {
              context: MemberSearchCategory.NoSelect,
              count: defaultItems.length,
            }),
          ...defaultItems.map(
            (data, i) =>
              renderDefaultItems?.(data, i) ||
              suggestionUserToIconListItem(data, searchMode)
          ),
        ]
      : [];

    if (selectedIconList.length) {
      output.push({
        layout: 'icon-list-layout',
        items: selectedIconList,
      });
    }

    if (defaultIconList.length) {
      output.push({
        layout: 'icon-list-layout',
        items: defaultIconList,
      });
    }

    // Showing top scorers by default
    const topScorerIconList: IconListLayoutItem[] = datasetTotalCount
      ? [
          renderTitle?.(datasetTotalCount) ||
            t('suggestion.search.count', {
              context: MemberSearchCategory.TopScore,
              count: datasetTotalCount,
            }),
          ...dataset.map(
            (data, i) =>
              renderDefaultItems?.(data, i) ||
              suggestionUserToIconListItem(data, searchMode)
          ),
        ]
      : [];

    if (topScorerIconList.length) {
      output.push({
        layout: 'icon-list-layout',
        settings: {
          disableSelectTooltip,
        },
        items: topScorerIconList,
      });
    }

    return output;
  }, [
    newUser,
    selectedItems,
    renderSelectedTitle,
    t,
    renderSelectedItems,
    showResult,
    defaultItems,
    renderDefaultTitle,
    renderNewUser,
    datasetTotalCount,
    renderTitle,
    searchMode,
    dataset,
    renderItems,
    disableSelectTooltip,
    renderDefaultItems,
  ]);
}
